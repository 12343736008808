import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const Create = () => import('./views/Create')
const Display = () => import('./views/Display')
const Statistic = () => import('./views/Statistic')
const Month = () => import('./views/Month')

const ROUTE_NAME = 'profile:coupon:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const CREATE = p('create')
const DISPLAY = p('display')
const STATISTIC = p('statistic')
const MONTH = p('month')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        label: 'COUPON_LIST',
        accessConfig: accessPermission([TYPES.OPERATOR_SALESMAN], options),
      },
    }),
    route(':id/detail/', Display, DISPLAY, {
      meta: {
        label: 'COUPON_DISPLAY',
        accessConfig: accessPermission([TYPES.OPERATOR_SALESMAN], options),
      },
      props: true,
    }),
    route('create/', Create, CREATE, {
      meta: {
        label: 'COUPON_CREATE',
        accessConfig: accessPermission([TYPES.SALESMAN], options),
      },
    }),
    route('statistic/', Statistic, STATISTIC, {
      meta: {
        label: 'COUPON_STATISTIC',
        accessConfig: accessPermission([TYPES.SALESMAN], options),
      },
    }),
    route('statistic/:month/:year/', Month, MONTH, {
      props: true,
      meta: {
        label: 'COUPON_MONTH',
        accessConfig: accessPermission([TYPES.SALESMAN], options),
      },
    }),
  ]
}
