export const cabinetOrder = {
  path: [
    'prefix',
    'label',
    'postfix',
  ],
  query: [
    'query',
  ],
}
