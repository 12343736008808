<template lang="pug">
include /mixins.pug
+b.card.--space_1
  +b.ds-panel.--space_3xl
    +e.element.--offset_bottom
      +b.P.ds-caption.--size_md.--size_lg-md.--variant_2 {{ _("Варианты доставки") }}
  validation-provider(
    tag="div"
    v-slot="{ errors }"
    name="deliveryOption"
    :rules="{ required: true }"
  )
    +b.ds-panel.--space_sm(v-if="errors[0]")
      +e.element.--offset_bottom
        +b.P.ds-caption.--size_xs.--color_red.--lh_default {{ errors[0] }}
    +b.g-row.--appearance_spaced.--space_xl
      +b.g-cell.g-cols(v-for="item in choices.deliveryOption")
        +b.LABEL.d-control-radio.--variant_flex
          +e.INPUT.element(
            type='radio'
            :value='item.value'
            v-model='formdata.deliveryOption'
            @change="$emit('delivery:change')"
          )
          +e.SPAN.label
          +e.SPAN.content
            +b.P.ds-caption.--size_sm.--color_grey {{ item.label }}
  +b.ds-panel.--space_xl.--space_2xl-xl(v-if="formdata.deliveryOption === 2 || formdata.deliveryOption === 3")
    +e.element.--offset_vertical
      +b.divider
  //- checkout-delivery-pickup(
  //-   v-if="formdata.deliveryOption === 1"
  //-   :formdata="formdata"
  //- )
  checkout-delivery-post-office(
    v-if="formdata.deliveryOption === 2 || formdata.deliveryOption === 3"
    :formdata="formdata"
    :choices="choices"
    @delivery:add:address="addAddress"
  )
  validation-provider(
    slim
    v-slot="{ errors }"
    name="deliveryId"
  )
    +b.P.ds-caption.--size_xs.--color_red.--lh_default(
      v-if="errors[0]"
    ) {{ errors[0] }}
</template>

<script>

export default {
  props: {
    formdata: {
      type: Object,
    },
    choices: {
      type: Object,
    },
  },

  methods: {
    /**
     * @param {object} data
     */
    addAddress(data) {
      this.$emit('delivery:add:address', data)
    },
  },
}
</script>
