import {
  createResource,
  putResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'profile'

export const PROFILE_RETRIEVE = prefixAPI('/user_info/info/', MODEL_PREFIX)
export const PROFILE_CHANGE = prefixAPI('/user_info/change/', MODEL_PREFIX)

export const profileRetrieveResource = createResource(PROFILE_RETRIEVE)
export const profileUpdateResource = createResource(PROFILE_CHANGE, putResource)
