import { camelize } from '@utils/case'

const rangeKeys = {
  0: 'gte',
  1: 'lte',
}

export default {
  computed: {
    preparedValues() {
      const localValues = JSON.parse(JSON.stringify(this.value))
      const valuesKeys = Object.keys(localValues)

      return valuesKeys.reduce((acc, key) => {
        if (key.includes('gte') || key.includes('lte')) {
          Object.keys(rangeKeys).forEach(index => {
            if (key.includes(rangeKeys[index])) {
              /**
               * Remove `lte` and `gte` postfixes from attribute name
               */
              const clearKey = key.replace(`__${rangeKeys[index]}`, '')

              const camelizedKey = /\d/.test(clearKey) ? clearKey : camelize(clearKey)
              /**
               * Create a new array if it doesn't exist
               */
              if (!acc[camelizedKey]) {
                acc[camelizedKey] = []
              }

              /**
               * Add min and max values to an array of range filter by right indexes
               */
              acc[camelizedKey][index] = localValues[key]
            }
          })
        } else {
          acc[key] = localValues[key]
        }

        return acc
      }, {})
    },
  },
}
