import { accessGuard } from '@md/permissions'
import { prefixRoutes } from '@md/routing'
import { prefixLanguage } from '@utils/urls'
import { createRoutes as createProfileRoutes } from '@app/Profile/router'

export function createRoutes(options) {
  return [
    prefixRoutes(prefixLanguage('/profile/'), createProfileRoutes(options)),
  ]
}

export function scrollBehavior({ savedPosition }) {
  if (savedPosition) {
    return savedPosition
  }

  return {
    x: window.pageXOffset,
    y: window.pageYOffset,
  }
}

export function createRouter(options = {}) {
  const { Router, Vue } = options
  const router = new Router({
    mode: 'history',
    base: '/',
    scrollBehavior,
    routes: createRoutes({ Router, Vue }),
  })

  router.beforeEach(accessGuard(options))

  return router
}
