import * as is from 'is_js'

export function snakelize(key) {
  const separator = '_'
  const split = /(?=[A-Z])/

  return key.split(split)
    .join(separator)
    .toLowerCase()
    .replace(/-+/img, '_')
}

export function camelize(key) {
  if (is.number(key)) {
    return key
  }
  key = key.replace(/__/, '%')
    .replace(/[-_\s]+(.)?/g, (_, a) => (a ? a.toUpperCase() : ''))
    .replace(/(%)(\S)/g, (_, a, b) => `_${b.toUpperCase()}`)
  return key.substr(0, 1).toLowerCase() + key.substr(1)
}
