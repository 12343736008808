<template>
  <resource-loader-controller
    :resource="resource"
    :need-auth-credentials="needAuthCredentials"
    v-slot="{ receive, loading, result }"
  >
    <delayed-executor-controller
      :delay="300"
      :receiver="receive"
      v-slot="{ receive: delayedReceive }"
    >
      <view-component
        ref="view"
        v-bind="$attrs"
        :value="value"
        @input="submit"
        @update="update"
        :filter="filter"
        :result="result"
        :loading="loading"
        :receive="delayedReceive"
        :rules="rules"
        :choices="choices"
        :lookup="filter.lookup"
        :mask="mask"
        :disabled="disabled"
        :generate-custom-label="generateCustomLabel"
        :initial-receive="initialReceive"
      />
    </delayed-executor-controller>
  </resource-loader-controller>
</template>

<script>
import { createResource } from '@resource/resource'
import ViewComponent from './Component'

export default {
  name: 'filter-autocomplete-multiselect-widget-controller',
  props: {
    value: {},
    filter: {},
    url: {},
    rules: {},
    choices: {},
    lookup: {},
    mask: {},
    disabled: {},
    needAuthCredentials: {
      type: Boolean,
      default: true,
    },
    initialReceive: {
      type: Boolean,
      default: false,
    },
    generateCustomLabel: {
      type: Function,
    },
  },

  components: {
    ViewComponent,
  },

  computed: {
    resource() {
      const { choiceUrl } = this.filter

      return createResource(choiceUrl)
    },
  },

  mounted() {
    if (this.initialReceive) {
      this.receive()
    }
  },

  methods: {
    submit(val) {
      this.$emit('input', val)
    },

    update(val) {
      this.$emit('update', val)
    },

    preselectValue(val) {
      this.$refs.view.preselectValue(val)
    },

    setValue(val) {
      this.$refs.view.setValue(val)
    },

    addSimpleOption(val) {
      this.$refs.view.addSimpleOption(val)
    },

    async receive(params) {
      await this.$refs.view.receiveWithParams(params)
    },
  },
}

</script>
