<template lang="pug">
include /mixins.pug
+b.sorting
  +e.SELECT.element(
    name="sorting",
    v-model="checked",
    @change="submit"
  )
    template(v-for="choice in choices")
      option(:value="choice.label") {{ choice.title }}
  +e.icon
    +b.I.icon-dropdown
</template>

<script>
export default {
  name: 'Sorting',

  props: {
    value: {},
    filters: {},
  },

  data() {
    return {
      checked: this.value,
      choices: [
        {
          title: this._('по умолчанию'),
          label: 'default',
        },
        {
          title: this._('по возрастанию цены'),
          label: 'price',
        },
        {
          title: this._('по убыванию цены'),
          label: '-price',
        },
        {
          title: this._('по имени от А до Я'),
          label: 'name',
        },
        {
          title: this._('по имени от Я до А'),
          label: '-name',
        },
      ],
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },

  mounted() {
    this.checked = this.normalize(this.value)
  },

  methods: {
    submit() {
      this.$nextTick(() => {
        const val = 'default' !== this.checked ? this.checked : null
        this.filters.filters.ordering = val
        this.$emit('input', this.filters)
      })
    },

    normalize(value) {
      if (!value) return []
      const checked = this.choices.find(el => {
        if (el.label === value) {
          return el
        }
        return false
      })
      return checked ? checked.label : null
    },
  },
}
</script>
