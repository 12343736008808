import {
  createResource,
  postResource,
  deleteResource,
} from '@resource/resource'
import { simpleTransformer } from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'catalog/basket'

const CART_UPDATE = prefixAPI('/', MODEL_PREFIX)
const CART_INFO = prefixAPI('/info/', MODEL_PREFIX)
const CART_ITEM_REMOVE = prefixAPI('/{id}/', MODEL_PREFIX)
const CART_PROMOCODE = prefixAPI('/add-promocode/', MODEL_PREFIX)

export const cartUpdateResource = createResource(CART_UPDATE, postResource)
export const cartInfoResource = createResource(CART_INFO)
export const cartItemRemoveResource = createResource(CART_ITEM_REMOVE, deleteResource, simpleTransformer)
export const cartPromocodeResource = createResource(CART_PROMOCODE, postResource)
