<template lang="pug">
  include /mixins
  +b.modal
    +b.close(@click.prevent='$emit("close")')
      +b.I.icon-close
    +b.P.ds-caption.--size_md.--size_lg-xl.--bold.--color_dark(v-if="meta.title") {{ meta.title }}
    +b.ds-panel--space_lg(v-if="meta.subtitle")
      +e.element--offset_top
        +b.P.ds-caption.--size_4xs.--size_xs-sm.--size_sm-lg.--color_dark {{ meta.subtitle }}
    +b.ds-panel--space_2xl
      +e.element--offset_top
        +b.g-row.--appearance_spaced
          +b.g-cell.g-cols.--6-xs
            +b.BUTTON.control-button.--variant_transparent(@click.prevent="submit(false)")
              +e.element
                +e.SPAN.text {{ _('Ні!') }}
          +b.g-cell.g-cols.--6-xs
            +b.BUTTON.control-button(@click.prevent="submit(true)")
              +e.element
                +e.SPAN.text {{ _('Так!') }}
</template>

<script>
export default {
  name: 'ConfirmModal',

  props: {
    meta: {},
    promise: {},
  },

  methods: {
    /**
     * @param {boolean} trigger
     */
    submit(trigger) {
      this.promise(trigger)
      this.$emit('close')
    },
  },
}
</script>
