const AuthLogin = () => import('./Login')
const AuthPasswordSet = () => import('./PasswordSet')
const AuthCompanyCreate = () => import('./CompanyCreate')
const AuthRegistration = () => import('./Registration/View')
const AuthRegistrationConfirm = () => import('./Registration/Confirm')

export default function install(Vue) {
  Vue.component('auth-login', AuthLogin)
  Vue.component('auth-password-set', AuthPasswordSet)
  Vue.component('auth-company-create', AuthCompanyCreate)
  Vue.component('auth-registration', AuthRegistration)
  Vue.component('auth-registration-confirm', AuthRegistrationConfirm)
}
