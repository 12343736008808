<template lang="pug">
  include /mixins
  +b.LABEL.d-control-input__label--static(:for='id')
    +b.g-row.--space_xs.--align_center
      +b.g-cell
        span {{ inputLabel }}
        span(
          v-if='isRequired'
          v-bem.is-required=''
        ) *
</template>

<script>
export default {
  name: 'StaticLabel',
  block: 'd-control-input',
  props: {
    id: {
      type: String,
      required: false,
    },
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
  },
}
</script>
