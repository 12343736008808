import { debounce, isEmpty } from 'lodash'
import { format } from 'date-fns'
import isShallowEqual from '@wordpress/is-shallow-equal'
import { snakelize } from '@utils/case'
import FilterUi from '../Ui'

export default {
  props: [
    'loading',
    'value',
    'receive',
    'all',
    'available',
    'pagination',
    'label',
    'needSearch',
    'filterKey',
  ],

  components: {
    FilterUi,
  },

  data() {
    return {
      prepared: {},
      debounceFilter: null,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return
        }

        this.prepare(filters)
      },
    },
  },

  computed: {
    totalCount() {
      return Object.hasOwnProperty.call(this.pagination, 'total') ? this.pagination.total : this.value.total
    },

    filters() {
      return (this.all && this.all.item) ? this.all.item : []
    },
  },

  created() {
    if (this.filterKey) {
      this.receive({ filter_key: this.filterKey })
    }
  },

  mounted() {
    const timeout = 300
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.debounceFilter = debounce(this.filter, timeout)
  },

  methods: {
    prepare(value) {
      this.prepared = value
    },

    handleInputFilter(value) {
      const hasDateFilterWithCurrentMonth = Object.values(this.filters).find(el => el.currentMonth)

      if (isEmpty(value) && hasDateFilterWithCurrentMonth) {
        const obj = {}

        Object.keys(this.filters).forEach(key => {
          if (this.filters[key].currentMonth) {
            const snakelizedKey = snakelize(key)

            const today = new Date()
            const firstDayOfMonth = format(today, '01.MM.yyyy')
            const currentDay = format(today, 'dd.MM.yyyy')

            obj[`${snakelizedKey}__gte`] = firstDayOfMonth
            obj[`${snakelizedKey}__lte`] = currentDay
          }
        })

        this.handleInput(obj)
      } else {
        this.handleInput(value)
      }
      this.debounceFilter()
    },

    handleInput(value) {
      this.prepare(value)
    },

    filter() {
      this.$emit('input', { ...this.value, filters: this.prepared, offset: 0 })
    },
  },
}
