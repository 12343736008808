<template lang="pug">
  include /mixins
  ui-modal-trigger(
    url='Modals/Common/Cart',
    v-slot="{ openPopup }"
    modalClasses="v--modal-xl"
  )
    +b.i-circle.--interactive.--variant_1(
      :title="hint"
      @click.prevent="openPopup"
    )
      +b.I.icon-cart
      +b.i-count.--variant_absolute.--variant_1
        +e.SPAN.content {{ itemsCount }}
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      hint: this._('Корзина'),
    }
  },

  async created() {
    if (window.isAuthenticated) {
      this.getCartState()
    }
  },

  computed: {
    ...mapState('cart', [
      'cart',
    ]),

    itemsCount() {
      return this.cart.divisionOrder.reduce((acc, el) => {
        acc += el.items.length

        return acc
      }, 0)
    },
  },

  methods: {
    ...mapActions('cart', [
      'getCartState',
    ]),
  },
}
</script>
