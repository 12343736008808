import { getCookie } from '@utils/cookies'
import { setAuthToken } from '@resource/middleware'
import { tokenRefreshResource } from '@api/jwt.service'

/**
 * Overrides `getTokenState`.
 *
 * Function that returns an object of JWT token state
 * @returns Object
 */
const getTokenState = () => {
  /**
   * Overrides `tokenIsActive`.
   *
   * `token` it is a cookie that has max-age=3500
   *
   * Get AUTH_TOKEN from cookie
   */
  const token = getCookie('AUTH_TOKEN')
  /**
   * Overrides `tokenIsActive`.
   *
   * `tokenIsActive` it is a cookie that has max-age=3000
   * we have 500 seconds offset (if compare with `token` max-age)
   * for emergencies
   *
   * Get AUTH_TOKEN_IS_ACTIVE state from cookie
   */
  const tokenIsActive = getCookie('AUTH_TOKEN_IS_ACTIVE')
  /**
   * Create variable that consist of the token that we got from the cookie
   * or if our cookie is empty we get the token from user's request
   */
  const { authToken } = window
  const jwtToken = ((authToken && authToken !== token && tokenIsActive) || !token) ? window.authToken : token

  /**
   * Create a convenient object of token state
   */
  const state = { tokenIsActive, jwtToken }

  return state
}

let isLoading = false

/**
 * @returns Promise
 */
const updateAuthToken = async () => {
  const promise = new Promise(resolve => {
    /**
     * Get state of JWT token
     */
    const { tokenIsActive, jwtToken } = getTokenState()

    /**
     * Check if user is authenticated (`window.isAuthenticated`)
     * and we have a token (`jwtToken`)
     * and this token is not active (`tokenIsActive`)
     *
     * Overrides `tokenIsActive`.
     *
     * `tokenIsActive` it is a cookie that has max-age=3000
     * we have 500 seconds offset (if compare with `token` max-age)
     * for emergencies
     */

    if (window.isAuthenticated && jwtToken && !tokenIsActive && !isLoading) {
      isLoading = true

      tokenRefreshResource.execute({}, { token: jwtToken }).then(res => {
        const { token } = res
        /**
         * Update cookies with new token
         */
        setAuthToken(token)
        resolve()

        isLoading = false
      })
    }
  })

  return promise
}

/**
 * Overrides `authHeader`.
 *
 * Function that return an object with the `Authorization` header
 * if token is active and empty object if token is not active
 * @returns Object
 */
const authHeader = () => {
  /**
   * Get state of JWT token
   */
  const { tokenIsActive, jwtToken } = getTokenState()

  /**
   * If the token is active we return an object with the `Authorization` header
   */
  if (tokenIsActive) {
    return { Authorization: `JWT ${jwtToken}` }
  }

  /**
   * If the token is not active we return an empty object
   */
  return {}
}

/**
 * @param {object} resource
 * @returns Promise
 */
export const resourceCaller = async resource => {
  const promise = new Promise(resolve => {
    /**
     * Overrides `resolvedMethod`.
     *
     * Function that adding an updated headers
     * to resource that we got from argument
     */
    const resolvedMethod = () => {
      /**
       * The `headers()` method adds updated headers to the resource
       */
      const resourceWithJWTHeader = resource.headers(authHeader())
      resolve(resourceWithJWTHeader)
    }

    /**
     * Get state of JWT token
     */
    const { tokenIsActive } = getTokenState()

    if (tokenIsActive) {
      /**
       * If the token is active we resolve this promise
       */
      resolvedMethod()
    } else if (isLoading) {
      /**
       * If request for update token is in loading
       * we start an interval where we check if the request has ended
       */
      const timeout = 1000
      const interval = setInterval(() => {
        if (!isLoading) {
          resolvedMethod()
          clearInterval(interval)
        }
      }, timeout)
    } else {
      /**
       * If the token is not active we invoke a request to update the token
       */
      updateAuthToken().then(() => {
        /**
         * When refresh request has been completed we resolve this promise
         */
        resolvedMethod()
      })
    }
  })

  return promise
}
