<template lang="pug">
  include /mixins
  div
    filters-renderer(
      :widgets-map="$options.widgetsMap"
      :prepared-filters="commonFilters"
      :update-multiple-values="updateMultipleValues"
      :update-value="updateValue"
      :value="preparedValues"
    )
    +b.i-panel.--variant_1
      +b.ds-panel.--space_lg(v-for='(filter, index) in specialFilters')
        +e.element(:class="{'ds-panel__element--offset_top': index}")
          tag(
            :tag="$options.widgetsMap[filter.filterType]"
            :parameters="parameters"
            :value="value[filter.key]"
            :filter-name="filter.key"
            :filter="filter"
            @input="updateValue(filter.key, $event)"
          )
    filters-renderer(
      :widgets-map="$options.widgetsMap"
      :prepared-filters="dynamicFilters"
      :update-multiple-values="updateMultipleValues"
      :update-value="updateValue"
      :value="preparedValues"
    )
</template>

<script>
import { mapState } from 'vuex'
import UiMixin from './mixins/UiMixin'
import FiltersRenderer from './FiltersRenderer'
import PrepareValuesMixin from './mixins/PrepareValuesMixin'

export default {
  components: {
    FiltersRenderer,
  },

  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'pagination',
    'additionalResource',
    'label',
  ],

  mixins: [
    UiMixin,
    PrepareValuesMixin,
  ],

  data() {
    return {
      specialFilters: [
        {
          name: this._('Новинки:'),
          key: 'is_new',
          label: 'new_products',
          filterType: 'checkbox-single',
        },
        {
          name: this._('Акции:'),
          key: 'is_offer',
          label: 'offer_products',
          filterType: 'checkbox-single',
        },
        {
          name: this._('Топ продаж:'),
          key: 'is_bestseller',
          label: 'special_products',
          filterType: 'checkbox-single',
        },
        {
          name: this._('В наличии:'),
          key: 'in_stock',
          label: 'in_stock',
          filterType: 'checkbox-single',
        },
      ],
    }
  },

  computed: {
    ...mapState('catalog', [
      'currentCurrency',
    ]),
    commonFilters() {
      const localFilters = JSON.parse(JSON.stringify(this.filters))
      const filtersKeys = Object.keys(localFilters)
      /**
       * Common template for attributes
       * @param {string} name
       * @param {string} filterType
       * @param {array} values
       * @param {string} filterKey
       */
      const createObj = (name, filterType, values, filterKey) => ({
        isClosed: false,
        name,
        filterType,
        values,
        key: filterKey,
      })

      return filtersKeys.reduce((acc, key) => {
        /**
         * Prettify price filter
         */
        if ('priceRange' === key && localFilters[key].maxprice && (localFilters[key].maxprice !== localFilters[key].minprice)) {
          /**
           * Create an array of min and max values
           */
          const { maxprice, minprice } = localFilters[key]
          const values = [minprice, maxprice]
          /**
           * Set filter title
           * Set filter type `range` for this attribute
           * Set attribute values
           * Set filter key `price`
           */
          const obj = createObj(this._('Цена'), 'range', values, 'price')
          /**
           * Set unit label for range filter
           * This label will be displayed near the min and max values
           */
          obj.unit = this.currentCurrency.currencyName
          acc.push(obj)
        /**
         * Prettify brand filter
         */
        } else if ('brands' === key && localFilters[key].length) {
          /**
           * Set filter title
           * Set filter type `checkbox` for this attribute
           * Set attribute values
           * Set filter key `brands`
           */
          const obj = createObj(this._('Бренд'), 'checkbox', localFilters[key], 'brand__in')
          acc.push(obj)
          /**
           * Prettify category filters
           */
        } else if ('categories' === key && localFilters[key].length) {
          /**
           * Set filter title
           * Set filter type `checkbox` for this attribute
           * Set attribute values
           * Set filter key `category`
           */
          const obj = createObj(this._('Категории'), 'checkbox', localFilters[key], 'category__in')
          acc.push(obj)
        }
        return acc
      }, [])
    },
    dynamicFilters() {
      const localFilters = JSON.parse(JSON.stringify(this.filters))
      const filtersKeys = Object.keys(localFilters)

      return filtersKeys.reduce((acc, key) => {
        if ('dynamicFilters' === key) {
          localFilters[key].forEach(item => {
            /**
             * Create an array of min and max values
             * for range type of dynamic filters
             */
            if ('range' === item.filterType) {
              item.values = [item.values.min, item.values.max]
            }
            /**
             * Adding `property_` prefix to attribute id
             * because it's needed for filtering
             */
            item.key = `property_${item.id}`
            acc.push(item)
          })
        }
        return acc
      }, [])
    },
  },

  created() {
    this.specialFilters = this.specialFilters.filter(el => el.label !== this.label)
  },
}
</script>
