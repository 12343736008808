import { comparisonInfoResource } from '@api/comparison.service'
import { currencyInfoResource } from '@api/currency.service'
import { resourceCaller } from '@resource/resourceCaller'

export function createStore() {
  return {
    namespaced: true,

    state: {
      comparison: [],
      currentCurrency: {},
    },

    actions: {
      async getComparisonInfo({ commit }) {
        const resource = await resourceCaller(comparisonInfoResource)
        const { data: { item } } = await resource.execute()
        commit('CHANGE_COMPARISON_INFO', item)
      },
      async getCurrentCurrency({ commit }) {
        const resource = await resourceCaller(currencyInfoResource)
        const { data: { item } } = await resource.execute()
        commit('SET_CURRENT_CURRENCY', item)
      },
    },

    mutations: {
      CHANGE_COMPARISON_INFO(state, result) {
        state.comparison = result
      },
      SET_CURRENT_CURRENCY(state, result) {
        state.currentCurrency = result
      },
    },
  }
}
