<template lang="pug">
  include /mixins
  +b.d-control-input
    d-control-static-multiselect(
      :multiple='false',
      :options='filter.values || []'
      v-model='checked'
      :name='filterName'
      :label="filterLabel"
      :input-label='filter.name',
      :disabled="disabled"
      :searchable="searchable"
      @input='submit'
      :maxHeight="224"
      :placeholder="_('Все')"
    )
      template(slot="noResult" slot-scope="{}")
        span {{ _("Элементы не найдены") }}
      template(slot="noOptions" slot-scope="{}")
        span {{ _("Список пуст") }}
</template>

<script>
import { isEmpty } from '@aspectus/vue-utils'

export default {
  name: 'SelectWidget',

  props: {
    value: {},
    filter: {},
    filterName: {},
    disabled: {
      default: false,
    },
    filterLabel: {
      default: 'name',
    },
    searchable: {
      default: false,
    },
  },

  data() {
    return {
      checked: '',
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },

  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },

  methods: {
    submit() {
      const val = this.normalizeTo(this.checked)
      this.$emit('input', val)
    },

    normalizeTo(value) {
      if (!value) return []
      const checked = this.filter.values
        .filter(el => value.value === el.value)
        .map(el => el.value)
      return checked
    },

    normalizeFrom(value) {
      if (isEmpty(value)) return ''
      /* eslint-disable-next-line */
      const checked = this.filter.values.find(el => el.value == value)
      return checked
    },
  },
}
</script>
