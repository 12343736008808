<template>
  <d-control-static-multiselect
    v-model="checked"
    v-bind="$attrs"
    ref="select"
    :name="filter.name"
    :label="filter.label || 'label'"
    :trackBy="filter.trackBy"
    :input-label="filter.inputLabel"
    :multiple="filter.multiple"
    :searchable="true"
    :internal-search="false"
    :options="localOptions"
    :show-no-results="false"
    :rules="rules"
    :taggable="filter.taggable"
    :disabled="disabled"
    :max-height="filter.maxHeight"
    :custom-label="generateCustomLabel"
    @search-change="search"
    @input="submit"
    @tag="addOption"
  >
    <template v-slot:noResult>{{ _('No elements found. Consider changing the search query.') }}</template>
    <template v-slot:noOptions>{{ _('Type to search.') }}</template>
    <template v-slot:afterList>
      <div
        v-observe-visibility="setVisibility"
        v-if="hasNextPage"
      >
      </div>
    </template>
  </d-control-static-multiselect>
</template>

<script>
const MIN_LENGTH = 3

export default {
  name: 'filter-autocomplete-multiselect-widget-component',

  props: {
    value: {},
    filter: {},
    receive: {},
    result: {},
    rules: {},
    disabled: {},
    choices: {},
    lookup: {},
    watchValue: {},
    generateCustomLabel: {
      type: Function,
    },
    initialReceive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMounted: true,
      isPagination: false,
      localOptions: [],
      query: null,
      checked: '',
      page: 1,
      localParams: {},
    }
  },

  computed: {
    hasNextPage() {
      if (!this.result) return false

      const pagination = this.result.pagination || this.result?.item?.pagination

      if (!pagination) return false

      const hasPageNumber = Boolean(pagination.next)
      const hasLimitOffset = pagination.limit ? pagination.total > pagination.limit + pagination.offset : null

      return hasPageNumber || hasLimitOffset
    },
  },

  watch: {
    result: {
      handler(nval) {
        this.setOptions(nval)
      },
    },

    value: {
      handler(nval) {
        if (!nval) {
          this.checked = []
        } else if ('string' === typeof nval) {
          this.addOption(nval)
        } else if (this.isMounted && nval) {
          this.preselectValue(nval)
          this.isMounted = false
        }
      },
    },

    choices: {
      handler(nval) {
        this.setCustomChoices(nval)
      },
    },
  },

  methods: {
    async receiveWithParams(params) {
      this.isPagination = false

      this.localParams = params

      await this.receive({ ...this.localParams })
    },

    setCustomChoices(data) {
      this.localOptions = data
    },

    preselectValue(nval) {
      this.checked = nval
      this.localOptions.unshift(nval)

      this.submit()
    },

    setValue(nval) {
      this.checked = nval

      const val = this.normalizeTo(this.checked)

      this.$emit('update', val)

      this.isMounted = false
    },

    setOptions(nval) {
      const items = nval.items || nval.item.items

      if (this.isPagination) {
        this.localOptions.push(...items)
      } else {
        this.localOptions = items || []
      }

      if (this.isMounted) {
        const obj = this.createOption(this.value)

        this.checked = obj

        this.isMounted = false
      }
    },

    submit() {
      this.$nextTick(() => {
        const val = this.normalizeTo(this.checked)

        this.$emit('input', val)
      })
    },

    normalizeTo(value) {
      if (!value) return []

      return value[this.lookup || 'id']
    },

    getClearQuery(query) {
      return query.replace(/_/g, '')
    },

    search(query) {
      this.isPagination = false

      if (this.result?.item?.pagination) {
        this.result.item.pagination.offset = 0
      }

      const minLength = this.filter.minLength || MIN_LENGTH

      if (query.length >= minLength || (!query && this.initialReceive)) {
        this.query = this.getClearQuery(query)

        const searchKey = this.filter.searchKey || 'query'

        this.receive({ [searchKey]: this.query, ...this.localParams })
      }
    },

    setVisibility(reached) {
      if (reached) {
        const { nextPage } = this.result?.pagination || {}

        this.isPagination = true

        const searchKey = this.filter.searchKey || 'query'

        if (nextPage) {
          this.receive({
            page: nextPage,
            [searchKey]: this.query,
            ...this.localParams,
          })
        } else {
          const { limit, offset } = this.result.item.pagination

          this.result.item.pagination.offset = offset + limit

          this.receive({
            limit: this.result.item.pagination.limit,
            offset: this.result.item.pagination.offset,
            [searchKey]: this.query,
            ...this.localParams,
          })
        }
      }
    },

    createOption(data) {
      const lookup = this.lookup || 'id'
      const { label } = this.filter
      const obj = {
        [lookup]: data,
        [label]: data,
      }

      return obj
    },

    addOption(data) {
      if (!this.filter.taggable) return

      const obj = this.createOption(data)

      this.localOptions.unshift(obj)
      this.checked = obj

      this.submit()
    },

    addSimpleOption(val) {
      this.localOptions.unshift(val)
      this.checked = val
    },
  },
}

</script>
