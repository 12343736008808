import { mapActions } from 'vuex'
import { createI18n } from '@/i18n'
import { createRouter } from './router'
import { createStoreObject } from './store'

export function createApp({ Vue, I18n, Router, Store }) {
  const i18n = createI18n({ Vue, I18n })
  const store = createStoreObject({ Vue, Store })
  const router = createRouter({ Vue, Router, store })

  Vue.prototype.allowEditPermission = !window.hijackerPermission || 'allow' === window.hijackerPermission

  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],

    async created() {
      if (window.isAuthenticated) {
        this.initialReceive()
        this.getCurrentCurrency()
      }
    },

    methods: {
      ...mapActions('users/auth', [
        'initialReceive',
      ]),
      ...mapActions('catalog', [
        'getCurrentCurrency',
      ]),
    },
  })

  return { app, i18n, router, store }
}
