<template lang="pug">
include /mixins.pug
+b.card.--space_1
  +b.ds-panel.--space_3xl
    +e.element.--offset_bottom
      +b.P.ds-caption.--size_md.--size_lg-md.--variant_2 {{ _("Комментарий к заказу") }}
  +b.g-row.--appearance_spaced.--space_xl
    +b.g-cell.g-cols.--12-xs
      d-control-static-textarea(
        autocomplete="off"
        v-model='formdata.comment'
        name='comment'
        ref='comment'
        rules='max:1000'
        :input-label='_("Вы можете оставить комментарий к заказу")'
        :rows="5"
      )
</template>

<script>
export default {
  props: {
    formdata: {
      type: Object,
    },
  },
}
</script>
