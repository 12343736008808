<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced
    +b.g-cell.g-cols.--6-xs
      d-control-static-datepicker(
        v-model='checked[0]',
        :config="{ maxDate: checked[1] }"
        autocomplete="off"
        name='startDate'
        :input-label='labels.startDate'
        append-icon="icon-date is-events-none"
        @on-change="debounceFn"
      )
    +b.g-cell.g-cols.--6-xs
      d-control-static-datepicker(
        v-model='checked[1]',
        :config="{ minDate: checked[0] }"
        autocomplete="off"
        name='endDate'
        :input-label='labels.endDate'
        append-icon="icon-date is-events-none"
        @on-change="debounceFn"
      )
</template>

<script>
import { format } from 'date-fns'
import { debounce, isEqual, isEmpty } from 'lodash'

export default {
  name: 'DateRangeWidget',

  props: {
    value: {},
    filter: {},
  },

  data() {
    return {
      checked: [null, null],
      debounceFn: () => {},
      labels: {
        startDate: this._('с'),
        endDate: this._('по'),
      },
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nval, oval) {
        this.setValue(nval, oval)
      },
    },
  },

  mounted() {
    const timeout = 200
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.debounceFn = debounce(this.submit, timeout)
  },

  methods: {
    filterValue(val) {
      return (val || []).filter(e => e)
    },

    setValue(nval, oval) {
      const newValue = this.filterValue(nval)
      const oldValue = this.filterValue(oval)
      const today = new Date()
      const firstDayOfMonth = format(today, '01.MM.yyyy')
      const currentDay = format(today, 'dd.MM.yyyy')

      if (isEmpty(newValue) && this.filter.currentMonth) {
        const value = [firstDayOfMonth, currentDay]

        this.$set(this, 'checked', value)
      } else if (!newValue[0] && newValue[1] && this.filter.currentMonth) {
        const value = [firstDayOfMonth, newValue[1]]

        this.$set(this, 'checked', value)
      } else if (!newValue[1] && newValue[0] && this.filter.currentMonth) {
        const value = [newValue[0], currentDay]

        this.$set(this, 'checked', value)
      } else if (!isEqual(newValue, oldValue)) {
        let value = nval

        if (!nval) {
          if (this.filter.currentMonth) {
            value = [firstDayOfMonth, currentDay]
          } else {
            value = []
          }
        }

        this.$set(this, 'checked', value)
      }
    },

    submit() {
      const filterIsEmpty = this.checked.every(el => !el)

      if (filterIsEmpty) {
        return
      }

      this.$nextTick(() => {
        const val = this.checked

        this.$emit('input', val)
      })
    },
  },
}
</script>
