<template lang="pug">
include /mixins.pug
+b.card.--space_1
  +b.ds-panel.--space_3xl
    +e.element.--offset_bottom
      +b.P.ds-caption.--size_md.--size_lg-md.--variant_2 {{ _("Условие оплаты") }}
  validation-provider(
    tag="div"
    v-slot="{ errors }"
    name="conditionPayment"
    :rules="{ required: true }"
  )
    +b.ds-panel.--space_sm(v-if="errors[0]")
      +e.element.--offset_bottom
        +b.P.ds-caption.--size_xs.--color_red.--lh_default {{ errors[0] }}
    +b.g-row.--appearance_spaced.--space_xl
      +b.g-cell.g-cols(v-for="item in choices.conditionPayment")
        +b.LABEL.d-control-radio.--variant_flex
          +e.INPUT.element(
            type='radio'
            :value='item.value'
            v-model='formdata.conditionPayment'
            @change="$emit('payment:change')"
          )
          +e.SPAN.label
          +e.SPAN.content
            +b.P.ds-caption.--size_sm.--color_grey {{ item.label }}
  validation-provider(
    slim
    v-slot="{ errors }"
    name="conditionPayment"
  )
    +b.P.ds-caption.--size_xs.--color_red.--lh_default(
      v-if="errors[0]"
    ) {{ errors[0] }}
</template>

<script>

export default {
  props: {
    formdata: {
      type: Object,
    },
    choices: {
      type: Object,
    },
  },
}
</script>
