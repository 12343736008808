import { TYPES } from '@md/users/constances'

export const navigationList = self => ([
  {
    title: self._('Пользователи'),
    icon: 'icon-users',
    permissions: [TYPES.OPERATOR_DEALER, TYPES.OPERATOR_SALESMAN],
    childs: [
      {
        key: 'USERS_DEALER_LIST',
        title: self._('Дилеры'),
        to: 'profile:users:dealer:list',
        permissions: [TYPES.OPERATOR_DEALER],
        childs: [
          {
            USERS_DEALER_SETTINGS: {
              title: self._('Анкета дилера'),
            },
          },
        ],
      },
      {
        key: 'USERS_SELLER_LIST',
        title: self._('Продавцы'),
        to: 'profile:users:seller:list',
        permissions: [TYPES.OPERATOR_SALESMAN],
        childs: [
          {
            USERS_SELLER_SETTINGS: {
              title: self._('Анкета продавца'),
            },
          },
        ],
      },
    ],
  },
  {
    title: self._('Заявки на регистрацию / добавление предприятия'),
    icon: 'icon-settings',
    permissions: [TYPES.OPERATOR_DEALER, TYPES.OPERATOR_SALESMAN],
    childs: [
      {
        key: 'APPLICATIONS_DEALER_LIST',
        title: self._('Заявки дилеров'),
        to: 'profile:applications:dealer:list',
        permissions: [TYPES.OPERATOR_DEALER],
        childs: [],
      },
      {
        key: 'APPLICATIONS_SELLER_LIST',
        title: self._('Заявки продавцов'),
        to: 'profile:applications:seller:list',
        permissions: [TYPES.OPERATOR_SALESMAN],
        childs: [],
      },
    ],
  },
  {
    title: self._('Регистрация талонов'),
    icon: 'icon-bookmark',
    permissions: [TYPES.SALESMAN, TYPES.OPERATOR_SALESMAN],
    childs: [
      {
        key: 'COUPON_CREATE',
        title: self._('Регистрация талона'),
        to: 'profile:coupon:create',
        permissions: [TYPES.SALESMAN],
        childs: [],
      },
      {
        key: 'COUPON_LIST',
        title: self._('Все регистрации талонов'),
        to: 'profile:coupon:list',
        permissions: [TYPES.OPERATOR_SALESMAN],
        childs: [
          {
            COUPON_DISPLAY: {
              title: self._('Заявка'),
            },
          },
        ],
      },
    ],
  },
  {
    title: self._('История регистраций'),
    icon: 'icon-date',
    permissions: [TYPES.SALESMAN],
    childs: [
      {
        key: 'COUPON_STATISTIC',
        title: self._('Все регистрации'),
        to: 'profile:coupon:statistic',
        permissions: [TYPES.SALESMAN],
        childs: [
          {
            COUPON_MONTH: {
              title: self._('Регистрации'),
            },
          },
        ],
      },
    ],
  },
  {
    title: self._('Заказы'),
    icon: 'icon-list',
    permissions: [TYPES.DEALER],
    childs: [
      {
        key: 'ORDERS_LIST',
        title: self._('Мои заказы'),
        to: 'profile:orders:list',
        permissions: [TYPES.DEALER],
        childs: [
          {
            ORDERS_DISPLAY: {
              title: self._('Заказ'),
            },
          },
        ],
      },
      {
        key: 'ORDERS_HISTORY',
        title: self._('История заказов'),
        to: 'profile:orders:history',
        permissions: [TYPES.DEALER],
        childs: [],
      },
    ],
  },
  {
    title: self._('Сервисный центр'),
    icon: 'icon-gear',
    permissions: [TYPES.DEALER],
    additionalPermissions: [!window.dealerIsSC],
    childs: [
      {
        key: 'SERVICE_LIST',
        title: self._('История сервисного обслуживания'),
        to: 'profile:service-repair:list',
        permissions: [TYPES.DEALER],
        additionalPermissions: [!window.dealerIsSC],
        childs: [],
      },
    ],
  },
  {
    title: self._('Сервисный центр'),
    icon: 'icon-gear',
    permissions: [TYPES.DEALER],
    additionalPermissions: [window.dealerIsSC],
    childs: [
      {
        title: self._('Ремонты'),
        icon: 'icon-gear',
        permissions: [TYPES.DEALER],
        additionalPermissions: [window.dealerIsSC],
        childs: [
          {
            key: 'REPAIR_LIST',
            title: self._('Все заявки на ремонт'),
            to: 'profile:repair:list',
            permissions: [TYPES.DEALER],
            additionalPermissions: [window.dealerIsSC],
            childs: [
              {
                REPAIR_DETAIL: {
                  title: self._('Заявка на ремонт'),
                },
              },
              {
                REPAIR_CREATE: {
                  title: self._('Создание заявки на ремонт'),
                },
              },
            ],
          },
        ],
      },
      {
        title: self._('Отчеты'),
        icon: 'icon-document',
        permissions: [TYPES.DEALER],
        additionalPermissions: [window.dealerIsSC],
        childs: [
          {
            key: 'REPAIR_ACT_LIST',
            title: self._('Все отчеты о выполненных работах'),
            to: 'profile:repair-act:list',
            permissions: [TYPES.DEALER],
            additionalPermissions: [window.dealerIsSC],
            childs: [
              {
                REPAIR_ACT_DETAIL: {
                  title: self._('Отчет'),
                },
              },
            ],
          },
        ],
      },
      {
        title: self._('Запчасти'),
        iconComponent: 'icon-spare-part',
        permissions: [TYPES.DEALER],
        additionalPermissions: [window.dealerIsSC],
        childs: [
          {
            key: 'SPARE_PART_LIST',
            title: self._('Все заказы запчастей'),
            to: 'profile:spare-part:list',
            permissions: [TYPES.DEALER],
            additionalPermissions: [window.dealerIsSC],
            childs: [],
          },
        ],
      },
    ],
  },
  {
    title: self._('Документооборот'),
    icon: 'icon-document',
    permissions: [TYPES.DEALER],
    childs: [
      {
        key: 'ACTS_LIST',
        title: self._('Акты сверки'),
        to: 'profile:acts:list',
        permissions: [TYPES.DEALER],
        childs: [
          {
            ACTS_DETAIL: {
              title: self._('Акт сверки'),
            },
          },
        ],
      },
      // {
      //   key: 'INVOICES_LIST',
      //   title: self._('Расходные накладные'),
      //   to: 'profile:invoices:list',
      //   permissions: [TYPES.DEALER],
      //   childs: [
      //     {
      //       INVOICES_DETAIL: {
      //         title: self._('Расходная накладная'),
      //       },
      //     },
      //   ],
      // },
      {
        key: 'LOYALTY_LIST',
        title: self._('Программа лояльности'),
        to: 'profile:loyalty:list',
        permissions: [TYPES.DEALER],
        childs: [],
      },
      {
        key: 'DELICERY_CARGO_LIST',
        title: self._('Мои отгрузки'),
        to: 'profile:cargo:list',
        permissions: [TYPES.DEALER],
        childs: [],
      },
    ],
  },
  {
    title: self._('Продавцы'),
    icon: 'icon-users',
    permissions: [TYPES.DEALER],
    childs: [
      {
        key: 'DEALER_SELLER_LIST',
        title: self._('Мои продавцы'),
        to: 'profile:dealer:seller:list',
        permissions: [TYPES.DEALER],
        childs: [],
      },
      {
        key: 'DEALER_SELLER_APLLICATION_LIST',
        title: self._('Заявки продавцов'),
        to: 'profile:dealer:seller:application:list',
        permissions: [TYPES.DEALER],
        childs: [],
      },
    ],
  },
  {
    title: self._('Ремонты'),
    icon: 'icon-gear',
    permissions: [TYPES.PARTNER],
    childs: [
      {
        key: 'REPAIR_LIST',
        title: self._('Все заявки на ремонт'),
        to: 'profile:repair:list',
        permissions: [TYPES.PARTNER],
        childs: [
          {
            REPAIR_DETAIL: {
              title: self._('Заявка на ремонт'),
            },
          },
          {
            REPAIR_CREATE: {
              title: self._('Создание заявки на ремонт'),
            },
          },
        ],
      },
    ],
  },
  {
    title: self._('Отчеты'),
    icon: 'icon-document',
    permissions: [TYPES.PARTNER],
    childs: [
      {
        key: 'REPAIR_ACT_LIST',
        title: self._('Все отчеты о выполненных работах'),
        to: 'profile:repair-act:list',
        permissions: [TYPES.PARTNER],
        childs: [
          {
            REPAIR_ACT_DETAIL: {
              title: self._('Отчет'),
            },
          },
        ],
      },
    ],
  },
  {
    title: self._('Запчасти'),
    iconComponent: 'icon-spare-part',
    permissions: [TYPES.PARTNER],
    childs: [
      {
        key: 'SPARE_PART_LIST',
        title: self._('Все заказы запчастей'),
        to: 'profile:spare-part:list',
        permissions: [TYPES.PARTNER],
        childs: [],
      },
    ],
  },
  {
    title: self._('Профильные данные'),
    icon: 'icon-user',
    permissions: [TYPES.DEALER, TYPES.SALESMAN, TYPES.PARTNER],
    childs: [
      {
        key: 'SETTINGS',
        title: self._('Личные данные'),
        to: 'profile:settings',
        permissions: [TYPES.DEALER, TYPES.SALESMAN, TYPES.PARTNER],
        childs: [],
      },
    ],
  },
  {
    title: self._('Инструкции'),
    icon: 'icon-document',
    permissions: [TYPES.SALESMAN],
    childs: [
      {
        key: 'INSTRUCTIONS_LIST',
        title: self._('Инструкция по использованию ЛК'),
        to: 'profile:instructions:list',
        permissions: [TYPES.SALESMAN],
        childs: [],
      },
    ],
  },
])
