const LanguageSwitch = () => import('./LanguageSwitch')
const CurrencySwitch = () => import('./CurrencySwitch')
const UserBar = () => import('./UserBar')
const CategoriesButton = () => import('./CategoriesButton')
const SidebarToggler = () => import('./SidebarToggler')
const HeaderView = () => import('./View')

export default function install(Vue) {
  Vue.component('language-switch', LanguageSwitch)
  Vue.component('currency-switch', CurrencySwitch)
  Vue.component('user-bar', UserBar)
  Vue.component('categories-button', CategoriesButton)
  Vue.component('sidebar-toggler', SidebarToggler)
  Vue.component('header-view', HeaderView)
}
