<script>
import { prefixLanguage } from '@utils/urls'
import { getCookie } from '@utils/cookies'
import { HIJACK_LINK } from '@api/hijack.service'

export default {
  methods: {
    loginAsAnotherUser(id) {
      const formdata = new FormData()
      const next = prefixLanguage('/profile/settings/')

      formdata.append('user_pk', id)
      formdata.append('csrfmiddlewaretoken', getCookie('csrftoken'))
      formdata.append('next', next)

      const params = {
        method: 'POST',
        body: formdata,
      }

      fetch(HIJACK_LINK, params).then(() => {
        window.location = next
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      loginAsAnotherUser: this.loginAsAnotherUser,
    })
  },
}
</script>
