<template lang="pug">
include /mixins.pug
+b.g-row.--appearance_spaced.--justify_between.--align_center
  +b.g-cell.g-cols.--12-xs(
    v-if="formdata.deliveryOption === 2"
    key="department-cell"
  )
    +b.ds-panel.--space_md
      +e.element.--offset_bottom
        +b.g-row.--appearance_nowrap.--align_center
          +b.g-cell.g-cols.--narrow-xs
            +b.ds-inliner.--size_xl.--color_green
              +e.body
                +b.I.icon-help-circle
          +b.g-cell.g-cols.--auto-xs
            +b.P.ds-caption.--size_xs.--color_green {{ _("Для пошуку поштового відділення введіть його назву, цифрову адресу відділення або номер відділення") }}
    +b.g-row.--appearance_spaced
      +b.g-cell.g-cols.--auto
        autocomplete-select(
          key="departmentAutocomplete"
          ref="departmentAutocomplete"
          :required="true"
          rules="required"
          :value="formdata.department"
          :filter="autocompleteFilters.department"
          :initial-receive="true"
          :generate-custom-label="generateDepartmentLabel"
          v-model="formdata.department"
        )
      +b.g-cell.g-cols.--narrow
        +b.ds-panel.--space_3xl
          +e.element.--offset_top
            ui-modal-trigger(
              url='Modals/Delivery/Department',
              :addDepartment="addDepartment"
              modalClasses='v--modal-md',
              v-slot="{ openPopup }"
            )
              +b.i-button(@click.prevent="openPopup")
                +e.wrapper
                  +e.icon
                    +b.I.icon-add
  +b.g-cell.g-cols.--12-xs(
    v-if="formdata.deliveryOption === 3"
    key="address-cell"
  )
    +b.g-row.--appearance_spaced
      +b.g-cell.g-cols.--auto
        d-control-static-multiselect(
          key="address"
          name="address"
          rules="required"
          :allow-empty="false"
          :searchable="true"
          :input-label="labels.address.label"
          :placeholder="labels.address.placeholder"
          :options="choices.address"
          v-model="formdata.address"
          label="title",
          :options-limit="6000"
          :custom-label="generateAddressLabel"
        )
          template(slot="noResult" slot-scope="{}")
            span {{ _("Элементы не найдены") }}
          template(slot="noOptions" slot-scope="{}")
            span {{ _("Список пуст") }}
      +b.g-cell.g-cols.--narrow
        +b.ds-panel.--space_3xl
          +e.element.--offset_top
            ui-modal-trigger(
              url='Modals/Delivery/Address',
              :addAddress="addAddress"
              modalClasses='v--modal-md',
              v-slot="{ openPopup }"
            )
              +b.i-button(@click.prevent="openPopup")
                +e.wrapper
                  +e.icon
                    +b.I.icon-add
</template>

<script>
import {
  generateDepartmentLabel,
  generateAddressLabel,
} from '@utils/common'
import {
  USER_DEPARTMENTS_LIST,
  userDepartmentsAddResource,
} from '@api/profile.service'
import AutocompleteSelect from '@app/Filters/Widgets/AutocompleteMultiselectWidget'
import { OpenMessageModal } from '@utils/submit'
import { resourceCaller } from '@resource/resourceCaller'

export default {
  props: {
    formdata: {
      type: Object,
    },
    choices: {
      type: Object,
    },
  },

  components: {
    AutocompleteSelect,
  },

  data() {
    return {
      generateDepartmentLabel,
      generateAddressLabel,
      labels: {
        address: {
          label: this._('Укажите адрес'),
          placeholder: this._('Выберите адрес доставки'),
        },
      },
      autocompleteFilters: {
        department: {
          choiceUrl: USER_DEPARTMENTS_LIST,
          name: 'department',
          inputLabel: this._('Укажите адрес склада'),
          placeholder: this._('Выберите адрес склада доставки'),
          label: 'name',
          trackBy: 'id',
          multiple: false,
          searchKey: 'search',
          minLength: 1,
        },
      },
    }
  },

  methods: {
    /**
     * @param {object} data
     */
    addAddress(data) {
      this.$emit('delivery:add:address', data)
    },

    /**
     * @param {object} data
     */
    async addDepartment(data) {
      const params = { id: data.department }
      const resource = await resourceCaller(userDepartmentsAddResource)

      resource.execute(params).then(async res => {
        const { data: { item } } = res
        const meta = {
          title: this._('Склад успешно добавлен в Ваш список складов'),
        }
        OpenMessageModal(meta, this)

        await this.getDepartments()

        this.formdata.department = item.id

        this.$refs.departmentAutocomplete.addSimpleOption(item)
      })
    },

    async getDepartments() {
      await this.$refs.departmentAutocomplete.receive()
    },
  },
}
</script>
