import BaseElements from './Base'
import ControlsElements from './Controls'
import PaginationElement from './Pagination'
import FiltersElements from './Filters'
import ListElements from './List'
import UiComponentsElements from './UiComponents'
import ProductElements from './Product'
import CartElements from './Cart'
import CompareElements from './Compare'
import AuthElements from './Authentication'
import FormsElements from './Forms'
import CheckoutElements from './Checkout'
import ServiceElements from './Service'
import BrandElements from './Brand'
import WarrantyElements from './Warranty'
import ProfileElements from './Profile/components'

export default function Components(Vue) {
  Vue.use(BaseElements)
  Vue.use(ControlsElements)
  Vue.use(PaginationElement)
  Vue.use(FiltersElements)
  Vue.use(ListElements)
  Vue.use(UiComponentsElements)
  Vue.use(ProductElements)
  Vue.use(CartElements)
  Vue.use(CompareElements)
  Vue.use(AuthElements)
  Vue.use(FormsElements)
  Vue.use(CheckoutElements)
  Vue.use(ServiceElements)
  Vue.use(BrandElements)
  Vue.use(WarrantyElements)
  Vue.use(ProfileElements)
}
