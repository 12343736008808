const UiClickOutside = () => import('./UiClickOutside')
const UiCountdown = () => import('./UiCountdown')
const UiSearch = () => import('./UiSearch')
const UiSwitch = () => import('./UiSwitch')
const UiTabs = () => import('./UiTabs')
const UiUploader = () => import('./UiUploader')
const UiDropdown = () => import('./UiDropdown')
const UiSlider = () => import('./UiSlider')
const UiModalTrigger = () => import('./UiModalTrigger')
const UiUploaderCamera = () => import('./UiUploaderCamera')

export default function install(Vue) {
  Vue.component('ui-click-outside', UiClickOutside)
  Vue.component('ui-countdown', UiCountdown)
  Vue.component('ui-search', UiSearch)
  Vue.component('ui-switch', UiSwitch)
  Vue.component('ui-tabs', UiTabs)
  Vue.component('ui-uploader', UiUploader)
  Vue.component('ui-dropdown', UiDropdown)
  Vue.component('ui-slider', UiSlider)
  Vue.component('ui-modal-trigger', UiModalTrigger)
  Vue.component('ui-uploader-camera', UiUploaderCamera)
}
