<template lang="pug">
include /mixins.pug
+b.ds-panel.--space_md.--space_xl-xl(v-if="page")
  +e.element--offset_vertical
    +b.g-row.--align_center.--appearance_spaced
      +b.g-cell.g-cols.--12-xs.--auto-xl(v-if="needAddBtns")
        +b.i-pagination.--offset
          +b.g-row.--align_center
            +b.g-cell.g-cols.--auto
              +b.g-row.--align_center.--justify_between.--justify_start-sm
                +b.g-cell
                  +b.P.ds-caption.--size_xs.--color_dark-grey {{  _("Об'єктів на сторінці")  }}
                +b.g-cell
                  d-control-static-multiselect.d-control-input--variant_5(
                    style="width: 120px"
                    :allow-empty="false",
                    :options="pageSizes",
                    v-model="pagelimit",
                    @input="changeLimit"
                  )
            +b.g-cell.is-visible-sm
              +b.P.ds-caption.--size_xs.--color_dark-grey {{ _('Показано') }} {{ pageObj.from || 0 }} {{ _('до') }} {{ page.count < pageObj.to ? page.count || 0 : pageObj.to || 0 }} {{ _('з') }} {{ page.count }} {{  _("об'єктів")  }}
      +b.g-cell.g-cols.--12-xs.--auto-xl
        +b.i-pagination(v-if="page && range.max > 1")
          +e.item.--prev(
            :class="{ 'is-disabled': !page.hasPrevious() }",
            @click.prevent="previous()"
          )
            +b.I.icon-arrow-left
          +e.content
            +e.item(
              v-if="page.hasPrevious()",
              @click.prevent="select(range.min)"
            ) {{ range.min }}
            +e.item.is-disabled(v-if="gaps.left") ...
            +e.item(
              v-for="page in fills.left",
              @click.prevent="select(page)"
            ) {{ page }}
            +e.item.is-active {{ page.getPageNumber() }}
            +e.item(
              v-for="page in fills.right",
              @click.prevent="select(page)"
            ) {{ page }}
            +e.item.is-disabled(v-if="gaps.right") ...
            +e.item(
              v-if="page.hasNext()",
              @click.prevent="select(range.max)"
            ) {{ range.max }}
          +e.item.--next(
            :class="{ 'is-disabled': !page.hasNext() }",
            @click.prevent="next()"
          )
            +b.I.icon-arrow-right
</template>

<script>
/* eslint-disable prefer-spread */
import { LimitOffsetPagination } from './Pagination'

export default {
  name: 'UiLimitPagination',

  props: {
    pagination: {},
    extra: {
      type: Number,
      default: 2,
    },
    needAddBtns: {
      type: Boolean,
      default: () => false,
    },
    reset: {},
  },

  data() {
    return {
      pageSizes: [10, 20, 40, 60, 80],
      pagelimit: 10,
      page: {},
      range: {
        min: 0,
        max: Infinity,
      },
      gaps: {
        left: false,
        right: false,
      },
      fills: {
        left: [],
        right: [],
      },
      one: 1,
      zero: 0,
      limit: 200,
      show: true,
    }
  },

  created() {
    if (this.page) this.recalcRange()
  },

  computed: {
    pageObj() {
      const to = this.page.limit + this.page.offset
      const from = this.page.offset + this.one
      return { to, from }
    },
  },

  watch: {
    pagination: {
      deep: true,
      immediate: true,
      handler() {
        this.page = new LimitOffsetPagination(this.pagination)
        this.pagelimit = this.pagination.limit
        this.show = true
      },
    },

    page() {
      this.recalcRange()
    },

    reset(nval) {
      if (nval) {
        this.show = true
      }
    },
  },

  methods: {
    recalcRange() {
      const [min, max] = this.page.getPageRange()
      const current = this.page.getPageNumber()
      const [start, end] = this.page.getPageRange(this.extra, current)
      const range = Array.apply(null, { length: end - start + this.one }).map(
        (x, i) => i + start
      )
      const currentIndex = range.indexOf(current)
      if (currentIndex !== -this.one) {
        this.fills.left = range.slice(this.zero, currentIndex)
        const minIndex = this.fills.left.indexOf(min)
        if (minIndex >= this.zero) {
          this.fills.left.splice(minIndex, this.one)
        }
        this.fills.right = range.slice(currentIndex + this.one)
        const maxIndex = this.fills.right.indexOf(max)
        if (maxIndex >= this.zero) {
          this.fills.right.splice(maxIndex, this.one)
        }
      } else {
        this.fills.left = []
        this.fills.right = this.fills.left
      }

      this.gaps.left =
        this.fills.left.length && this.fills.left[0] !== min + this.one
      this.gaps.right =
        this.fills.right.length &&
        this.fills.right[this.fills.right.length - this.one] !== max - this.one
      this.range.min = min
      this.range.max = max
    },

    select(number) {
      this.emitValue(this.page.getParameters(number))
    },

    next() {
      this.emitValue(this.page.next())
    },

    previous() {
      this.emitValue(this.page.previous())
    },

    changeLimit(val) {
      this.emitValue({ limit: val })
    },

    emitValue(val) {
      this.$emit('input', val)
    },
  },
}
</script>
