import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const ApplicationList = () => import('./views/ListApplication')

const ROUTE_NAME = 'profile:dealer:seller:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const APPLICATION_LIST = p('application:list')

export function createRoutes(options) {
  return [
    route('seller/list/', List, LIST, {
      meta: {
        label: 'DEALER_SELLER_LIST',
        accessConfig: accessPermission([TYPES.DEALER], options),
      },
    }),
    route('seller/application/list/', ApplicationList, APPLICATION_LIST, {
      meta: {
        label: 'DEALER_SELLER_APLLICATION_LIST',
        accessConfig: accessPermission([TYPES.DEALER], options),
      },
    }),
  ]
}
