import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')

const ROUTE_NAME = 'profile:loyalty:'

const p = name => ROUTE_NAME + name

const LIST = p('list')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        label: 'LOYALTY_LIST',
        accessConfig: accessPermission([TYPES.DEALER], options),
      },
    }),
  ]
}
