<template lang="pug">
include /mixins.pug
compare-toggle-button(
  v-slot="{ compare }"
  :product-id="productId"
)
  +b.icon-button(
    @click.prevent="compare"
    :title="_('Удалить из избранного')"
  )
    +b.I.icon-trash
</template>

<script>
export default {
  props: {
    productId: {
      type: Number,
    },
  },
}
</script>
