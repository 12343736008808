import HeaderElements from './Header'
import CommonElements from './Common'
import ModalWrapperElements from './ModalWrapper'
import FooterElements from './Footer'

export default function Components(Vue) {
  Vue.use(HeaderElements)
  Vue.use(CommonElements)
  Vue.use(ModalWrapperElements)
  Vue.use(FooterElements)
}
