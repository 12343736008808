<template lang='pug'>
  include /mixins
  +b.i-filter.relative
    +e.button
      +b.BUTTON.control-button.--variant_green(@click.prevent="openFilter")
        +e.element
          +e.SPAN.text {{ _("Фильтр") }}
    +e.view(:class="{'is-active': isActive}")
      +b.blur(
        @click.prevent="hideFilter"
        :class="{'is-hidden': !isActive}"
      )
      +e.wrapper
        +e.main
          +b.i-preloader.--variant_1.--absolute(:class="{'is-active': loading}")
            +e.item.--size_md
          +e.heading
            +b.i-panel.--variant_1
              +b.g-row.--appearance_spaced.--justify_between.--align_center
                +b.g-cell.g-cols
                  +b.P.ds-caption.--size_sm.--bold {{ _('Фильтры') }}
                +b.g-cell.g-cols
                  +b.i-link.--color_green(@click.prevent='handleInputFilter({}, "clear")')
                    +e.icon.--offset_none
                      +b.I.icon-reset
          +e.content
            filter-ui(
              ref="filterUi"
              :disabled="loading"
              :value="prepared"
              :filters="filters"
              :pagination='pagination'
              :additional-resource="additionalResource"
              :label="label"
              @input="handleInputFilter"
            )
        +e.footer
          +b.BUTTON.control-button.--variant_green(@click.prevent="hideFilter")
            +e.element
              +e.SPAN.text {{ _("Применить") }}
</template>

<script>
import ControllerMixin from './mixins/ControllerMixin'

export default {
  mixins: [ControllerMixin],
}
</script>
