import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const TOKEN_AUTH = prefixAPI('api-token-auth/')
const TOKEN_REFRESH = prefixAPI('api-token-refresh/')
const TOKEN_VERIFY = prefixAPI('api-token-verify/')

export const tokenAuthResource = createResource(TOKEN_AUTH, postResource)
export const tokenRefreshResource = createResource(TOKEN_REFRESH, postResource)
export const tokenVerifyResource = createResource(TOKEN_VERIFY, postResource)
