<script>
import { mapActions } from 'vuex'
import { cartUpdateResource } from '@api/cart.service'
import { getParsedErrors } from '@app/Forms/FormMixin'
import { resourceCaller } from '@resource/resourceCaller'

export default {
  name: 'CartAddButton',

  props: {
    product: {
      default: null,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    color: {
      type: Number,
    },
  },

  data() {
    return {
      formdata: {},
    }
  },

  methods: {
    ...mapActions('cart', [
      'getCartState',
    ]),

    async addProduct() {
      this.formdata = {
        count: this.quantity,
        product: this.product.id,
      }
      if (this.color) {
        this.formdata.color = this.color
      }
      const resource = await resourceCaller(cartUpdateResource)
      resource.execute({}, this.formdata).then(() => {
        this.getCartState()
        const info = {
          title: this._('Товар был добавлен в корзину'),
        }
        this.openInfoModal(info)
      }).catch(async error => {
        const parsed = await getParsedErrors(error)
        const [errorMessage] = parsed.count || parsed.nonFieldErrors
        if (errorMessage) {
          const info = {
            title: errorMessage,
          }
          this.openInfoModal(info)
        }
      })
    },

    openInfoModal(info) {
      const modal = () => import('@app/Modals/Common/Info')
      this.$modal.show(modal, {
        info,
        timeout: 1500,
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      addProduct: this.addProduct,
    })
  },
}
</script>
