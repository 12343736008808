const iconsComp = require.context('@/js/icons/', true, /\.vue$/)

export default function install(Vue) {
  /* eslint-disable no-restricted-syntax */
  for (const comp of iconsComp.keys()) {
    const name = comp.replace(/^\.\//, '').replace(/\.\w+$/, '')

    Vue.component(`Icon${name}`, () => import(`@/js/icons/${name}.vue`))
  }
}
