<template lang='pug'>
  include /mixins
  +b.i-catalog.--variant_default
    slot(name="heading")
    catalog-sidebar(
      :filters="filters"
      @update:filters="changeFilters"
      :pagination="pagination",
      :attributesResource='attributesResource',
      :additionalResource="additionalResource"
      :filterKey="filterKey"
      :label='label'
      :filterName="filterName"
      :needSearch="needSearch"
    )
    +e.wrapper.relative
      +b.i-preloader.--absolute.--variant_1.--variant_2(:class="{'is-active': loading}")
        +e.item.--size_md
      slot(name="content")
    ui-limit-pagination(
      :pagination='pagination'
      @input='handlePaginate'
    )
</template>

<script>

import CatalogSidebar from './Sidebar'

export default {
  components: {
    CatalogSidebar,
  },

  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'label',
    'filterName',
    'needSearch',
    'additionalResource',
    'needAddBtns',
    'filterKey',
  ],

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.changeFilters(this.parameters)
    },

    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
  },
}

</script>
