const ProductCard = () => import('./Card')
const ProductQuantity = () => import('./Quantity')
const ProductMainSlider = () => import('./MainSlider')
const ProductOrder = () => import('./Order')
const ProductView = () => import('./View')
const ProductSimpleCard = () => import('./SimpleCard')
const ProductPreviewCard = () => import('./PreviewCard')
const ProductCardsSlider = () => import('./CardsSlider')
const ProductBadges = () => import('./Badges')

export default function Components(Vue) {
  Vue.component('product-card', ProductCard)
  Vue.component('product-quantity', ProductQuantity)
  Vue.component('product-main-slider', ProductMainSlider)
  Vue.component('product-order', ProductOrder)
  Vue.component('product-view', ProductView)
  Vue.component('product-simple-card', ProductSimpleCard)
  Vue.component('product-preview-card', ProductPreviewCard)
  Vue.component('product-cards-slider', ProductCardsSlider)
  Vue.component('product-badges', ProductBadges)
}
