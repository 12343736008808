/* eslint-disable */
import { renderSlim } from '@aspectus/vue-utils'
import { urlGetter } from '@utils/friendly'

const same = x => x

export default {
  name: 'router-parameters-controller',

  props: {
    initial: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: Function,
      default: same,
    },
    to: {
      type: Function,
      default: same,
    },
    baseUrl: {},
    getData: {},
  },

  data() {
    return {
      parameters: this.initial,
      friendlyUrl: {
        filters: {},
      },
      isPopstate: false,
      // isMounted: true,
    }
  },
  mounted() {
    window.addEventListener('popstate', () => {
      this.parameters = this.from({
        ...this.initial,
      })
      this.isPopstate = true
      this.$nextTick(() => {
        this.getData()
      })
      const timeout = 400
      setTimeout(() => {
        this.isPopstate = false
      }, timeout)
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.parameters = this.from({
          ...this.initial,
        })
      },
    },
  },

  methods: {
    updateUrl(parameters) {
      if (this.isPopstate) return
      const query = this.to(parameters)
      const order = {
        path: [
          'prefix',
        ],
        query: [
          'query',
        ],
      }
      const shift = 1
      const currentPage = Math.floor(
        query.offset / query.limit
      ) + shift
      const page = currentPage > shift ? currentPage : null
      const fullUrl = urlGetter([this.baseUrl], order, { ...query, page })
      history.pushState({ url: fullUrl }, '', fullUrl)
    },

    changeParameters(value) {
      this.parameters = value
      this.updateUrl(this.parameters)
      // if (this.isMounted) {
      //   this.isMounted = false
      // } else {
      //   this.parameters = value
      //   this.updateUrl(this.parameters)
      // }
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      parameters: this.parameters,
      changeParameters: this.changeParameters,
      updateUrl: this.updateUrl,
    }), h, 'tag')
  },
}
