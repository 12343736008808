import {
  createResource,
  putResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'profile/currency'

const CURRENCY_LIST = prefixAPI('/', MODEL_PREFIX)
const CURRENCY_CHANGE = prefixAPI('/change/', MODEL_PREFIX)
const CURRENCY_INFO = prefixAPI('/info/', MODEL_PREFIX)
const CURRENCY_DATA = prefixAPI('currency/')

export const currencyListResource = createResource(CURRENCY_LIST)
export const currencyChangeResource = createResource(CURRENCY_CHANGE, putResource)
export const currencyInfoResource = createResource(CURRENCY_INFO)
export const currencyDataResource = createResource(CURRENCY_DATA)
