<template lang="pug">
  include /mixins.pug
  div
    +b.g-row.--space_xs.--appearance_spaced
      +b.g-cell(v-for="attr in choices")
        +b.LABEL.d-control-checkbox.--variant_1.--size_xl(
          :style="generateBackground(attr.values)"
        )
          +e.INPUT.element(
            name="color"
            type='radio'
            :value="attr.id"
            v-model='checked'
            @change="$emit('input', checked)"
          )
          +e.label.is-visible-xl(
            v-tooltip.auto="{ content: attr.color }"
          )
          +e.label.is-hidden-xl
</template>

<script>
export default {
  name: 'ColorWidget',

  props: {
    choices: {},
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.checked = nval
      },
    },
  },

  data() {
    return {
      checked: null,
    }
  },

  methods: {
    generateBackground(values) {
      const percent = Math.round(100 / values.length)
      const colors = values.reduce((acc, color, index, arr) => {
        const ind = index + 1
        const multiply = percent * ind
        const firstType = `${color} ${multiply}%`
        const secondType = `${color} ${multiply - percent}%`

        if (!index) {
          acc.push(firstType)
        } else if (ind !== arr.length) {
          acc.push(secondType)
          acc.push(firstType)
        } else {
          acc.push(secondType)
        }

        return acc
      }, [])
      const gradiend = 1 < colors.length ? `linear-gradient(135deg, ${colors.join(', ')})` : `${colors[0]}`

      return `--checker-color: ${gradiend}; --label-bg: ${gradiend};`
    },
  },
}
</script>
