<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced.--align_end
    +b.g-cell.g-cols.--auto
      +b.g-row.--appearance_spaced.--align_end
        +b.g-cell.g-cols.--12-xs.--6-sm.--4-md.--6-xl.--4-hd.--3-mac(v-if="needSearch")
          tag(
            :tag="$options.widgetsMap['search']"
            :parameters="parameters"
            :value="value['search']"
            @input="updateValue('search', $event)"
          )
        +b.g-cell.g-cols.--12-xs.--6-sm.--4-md.--6-xl.--4-hd.--3-mac(
          v-for="(filter, key) in preparedFilters"
        )
          tag(
            :tag="$options.widgetsMap[filter.filterType]"
            :available="available"
            :parameters="parameters"
            :value="preparedValues[key]"
            :filter-name="key"
            :filter="filter"
            @input="'date' === filter.filterType ? updateMultipleValues(key, $event) : updateValue(key, $event)"
          )
        slot(v-if="resetButtonPosition.inside")
    slot(v-if="resetButtonPosition.outside")
</template>

<script>
import { DESKTOP_MEDIA, HD_MEDIA, MAC_MEDIA } from '@utils/constances'
import UiMixin from '../mixins/UiMixin'
import PrepareValuesMixin from '../mixins/PrepareValuesMixin'

export default {
  props: [
    'value',
    'filters',
    'available',
    'pagination',
    'needSearch',
  ],

  mixins: [
    UiMixin,
    PrepareValuesMixin,
  ],

  data() {
    return {
      isDesktop: window.innerWidth >= DESKTOP_MEDIA,
      resetButtonPosition: {
        inside: false,
        outside: false,
      },
    }
  },

  mounted() {
    if (this.isDesktop) {
      window.addEventListener('resize', this.initResizeListener)
    }
  },

  beforeDestroy() {
    if (this.isDesktop) {
      window.removeEventListener('resize', this.initResizeListener)
    }
  },

  watch: {
    filters: {
      immediate: true,
      depp: true,
      handler() {
        this.initResizeListener()
      },
    },
  },

  computed: {
    preparedFilters() {
      const localFilters = JSON.parse(JSON.stringify(this.filters))
      const filtersKeys = Object.keys(localFilters)

      filtersKeys.forEach(key => {
        if ('select' === localFilters[key].filterType) {
          localFilters[key].values = localFilters[key].values.map(val => {
            const [value, name] = val
            return { name, value }
          })
        }
      })

      return localFilters
    },
  },

  methods: {
    initResizeListener() {
      const windowWidth = window.innerWidth
      const filtersLength = Object.keys(this.filters).length
      const POSITION_MAP = [
        {
          media: MAC_MEDIA <= windowWidth,
          condition: 4 === filtersLength && !this.needSearch || (3 === filtersLength && this.needSearch),
        },
        {
          media: HD_MEDIA <= windowWidth && windowWidth < MAC_MEDIA,
          condition: 3 === filtersLength && !this.needSearch || (2 === filtersLength && this.needSearch),
        },
        {
          media: DESKTOP_MEDIA <= windowWidth && windowWidth < HD_MEDIA,
          condition: 2 === filtersLength && !this.needSearch || (1 === filtersLength && this.needSearch),
        },
      ]

      const option = POSITION_MAP.find(el => el.condition && el.media)

      if (option) {
        this.resetButtonPosition.outside = true
        this.resetButtonPosition.inside = false
      } else {
        this.resetButtonPosition.outside = false
        this.resetButtonPosition.inside = true
      }
    },
  },
}
</script>
