import {
  createResource,
  postResource,
  deleteResource,
  putResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'
import { simpleTransformer } from '@resource/transformer'

const MODEL_PREFIX = 'catalog'

const COMPARISON_UPDATE = prefixAPI('/comparison/{id}/', MODEL_PREFIX)
const COMPARISON_PRODUCT_REMOVE = prefixAPI('/comparison/{id}/', MODEL_PREFIX)
const COMPARISON_CREATE = prefixAPI('/comparison/', MODEL_PREFIX)
const COMPARISON_INFO = prefixAPI('/comparison/', MODEL_PREFIX)

// Add products to comparison
export const comparisonCreateResource = createResource(COMPARISON_CREATE, postResource)
// Remove comparison item by comparison item id
export const comparisonDeleteResource = createResource(COMPARISON_UPDATE, deleteResource, simpleTransformer)
// Get comparison info for header
export const comparisonInfoResource = createResource(COMPARISON_INFO)
// Get detail comparison info by comparison item id
export const comparisonDetailResource = createResource(COMPARISON_UPDATE)
// Remove product from comparison
export const comparisonProductRemoveResource = createResource(COMPARISON_PRODUCT_REMOVE, putResource)
