<template lang="pug">
include /mixins.pug
+b.card
  +b.ds-panel.--space_xl
    +e.element.--offset_bottom
      +b.P.ds-caption.--size_md.--size_2md-md.--size_lg-xl.--variant_2 {{ _("Общая сумма") }}
  +b.ds-panel.--space_xl
    +e.element.--offset_bottom
      +b.P.ds-caption.--color_main.--size_3xl.--bold {{ cartInfo.totalAmount|prettifyPrice }} {{ currentCurrency.currencyName }}
  +b.ds-panel.--space_xl
    +e.element.--offset_bottom
      +b.P.ds-caption.--size_sm.--size_md-xl.--variant_2 {{ _("Сумма по подразделениям") }}
  +b.ds-panel.--space_xl
    +e.element.--offset_bottom
      +b.g-row.--appearance_spaced
        +b.g-cell.g-cols.--12-xs(v-for="division in cartInfo.divisionOrder")
          +b.g-row
            +b.g-cell.g-cols.--6-xs
              +b.P.ds-caption.--size_sm.--color_green.--bold {{ division.title }}:
            +b.g-cell.g-cols.--6-xs
              +b.P.ds-caption.--size_sm.--bold.--appearance_right {{ division.sumPrice|prettifyPrice }} {{ currentCurrency.currencyName }}
  +b.ds-panel.--space_xl
    +e.element.--offset_bottom
      validation-provider(
        tag="div"
        v-slot="{ errors }"
        name="isDataCorrect"
      )
        +b.LABEL.d-control-checkbox.--variant_flex
          +e.INPUT.element(
            name="isDataCorrect"
            type='checkbox'
            v-model='formdata.isDataCorrect'
          )
          +e.label
          +e.SPAN.content
            +b.P.ds-caption.--size_sm.--color_grey {{ _("Все данные верны! Не связываться перед отправкой") }}
        +b.error-label(v-if="errors[0]") {{ errors[0] }}
  validation-provider(
    slim
    v-slot="{ errors }"
    name="nonFieldErrors"
  )
    +b.ds-panel.--space_xl(v-if="errors[0]")
      +e.element.--offset_bottom
        +b.P.ds-caption.--size_xs.--color_red.--lh_default {{ errors[0] }}
  +b.BUTTON.control-button.--variant_green(type="submit")
    +e.element
      +e.SPAN.text {{ _("Оформить заказ") }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    formdata: {
      type: Object,
    },
    cartInfo: {
      type: Object,
    },
  },

  computed: {
    ...mapState('catalog', [
      'currentCurrency',
    ]),
  },
}
</script>
