export const TYPES = {
  DEALER: 1,
  SALESMAN: 2,
  OPERATOR_DEALER: 3,
  OPERATOR_SALESMAN: 4,
  CLIENT: 5,
  PARTNER: 6,
}

export const USER_TYPES = {
  [TYPES.DEALER]: { isDealer: true },
  [TYPES.SALESMAN]: { isSalesman: true },
  [TYPES.OPERATOR_DEALER]: { isOperatorDealer: true },
  [TYPES.OPERATOR_SALESMAN]: { isOperatorSalesman: true },
  [TYPES.CLIENT]: { isClient: true },
  [TYPES.PARTNER]: { isPartner: true },
}
