import {
  createResource,
  postResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const COMPANIES_LIST = prefixAPI('companies/')
const CABINET_FILTER = prefixAPI('delivery/filter-values/{filter_key}/')
const TERRITORY_LIST = prefixAPI('territory-list/')
const REGISTRATION_ROLES = prefixAPI('registration-roles/')
const COMPANY_BECOME_PARTNER = prefixAPI('company-become-partnersc/')

export const companiesListResource = createResource(COMPANIES_LIST)
export const cabinetFilterResource = createResource(CABINET_FILTER)
export const territoryListResource = createResource(TERRITORY_LIST)
export const registrationRolesResource = createResource(REGISTRATION_ROLES)
export const companyBecomePartnerResource = createResource(COMPANY_BECOME_PARTNER, postResource)
