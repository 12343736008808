import Vue from 'vue'

Vue.directive('read-more', {
  bind: node => {
    window.onload = () => {
      const maxHeight = 260
      const nodeHeight = node.scrollHeight
      const trigger = node.querySelector('.js-trigger')

      if (nodeHeight > maxHeight) {
        node.classList.add('is-collapsed')
      } else {
        node.classList.add('is-visible')
      }

      trigger.onclick = () => {
        node.classList.remove('is-collapsed')
        node.classList.add('is-visible')
      }
    }
  },
})
