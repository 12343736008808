import {
  baseResource,
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'
import { friendlyUrlGenerator } from '@utils/friendly'
import { cabinetOrder } from '@api/order'

const MODEL_PREFIX = 'delivery'

const DELIVERY_REPLACEMENT = prefixAPI('/replacement/', MODEL_PREFIX)
const DELIVERY_EXCHANGE = prefixAPI('/exchange/', MODEL_PREFIX)
const DELIVERY_SERVICES = prefixAPI('/services/{?locality__region}', MODEL_PREFIX)
// const DELIVERY_REPAIR = prefixAPI('/repair', MODEL_PREFIX)
// const DELIVERY_REPAIR_REQUEST = prefixAPI('/repair/', MODEL_PREFIX)
const DELIVERY_REGIONS = prefixAPI('/regions/', MODEL_PREFIX)
export const DELIVERY_LOCALITIES = prefixAPI('/localities/{region}/{?search,page}', MODEL_PREFIX)
export const DELIVERY_DEPARTMENTS = prefixAPI('/departments/{locality}/{?search,page}', MODEL_PREFIX)
const DELIVERY_RECLAMATION = prefixAPI('/reclamation/', MODEL_PREFIX)
const DELIVERY_TICKET = prefixAPI('/ticket/', MODEL_PREFIX)
const DELIVERY_TICKET_LIST = prefixAPI('/ticket', MODEL_PREFIX)
const DELIVERY_TICKET_BY_MONTH = prefixAPI('/ticket/by-month/{month}/{year}/', MODEL_PREFIX)
const DELIVERY_LOYALTY = prefixAPI('/loyalty_program/', MODEL_PREFIX)
const DELIVERY_ORDER_COMPLETE = prefixAPI('/additional_products/', MODEL_PREFIX)
const DELIVERY_DECLARATION_UPDATE_LIST = prefixAPI('/cargo/update_declarations', MODEL_PREFIX)
const DELIVERY_DECLARATION_DETAIL = prefixAPI('/cargo/{id}/', MODEL_PREFIX)
const DELIVERY_SERIAL_NUMBER_LIST = prefixAPI('/serial/{id}/get_serial_number/', MODEL_PREFIX)
const DELIVERY_ACTS_NOTIFICATION = prefixAPI('/update-notification/', MODEL_PREFIX)
const DELIVERY_FIND_DEPARTMENT_BY_INDEX = prefixAPI('/department-by-index/', MODEL_PREFIX)

export const AUTOCOMPLETE_LOCALITIES_URL = prefixAPI('/localities-autocomplete/{query}/{?page}', MODEL_PREFIX)

export const deliveryReplacementResource = createResource(DELIVERY_REPLACEMENT, postResource)
export const deliveryExchangeResource = createResource(DELIVERY_EXCHANGE, postResource)
export const deliveryServicesResource = createResource(DELIVERY_SERVICES)
// export const deliveryRepairRequestResource = createResource(DELIVERY_REPAIR_REQUEST, postResource)
export const deliveryRegionsResource = createResource(DELIVERY_REGIONS)
export const deliveryLocalitiesResource = createResource(DELIVERY_LOCALITIES)
export const deliveryDepartmentsResource = createResource(DELIVERY_DEPARTMENTS)
export const deliveryReclamationResource = createResource(DELIVERY_RECLAMATION, postResource)
export const deliveryTicketCreateResource = createResource(DELIVERY_TICKET, postResource)
export const deliveryTicketByMonthResource = createResource(DELIVERY_TICKET_BY_MONTH)
export const deliveryLoyaltyResource = createResource(DELIVERY_LOYALTY)
export const deliveryOrderCompleteResource = createResource(DELIVERY_ORDER_COMPLETE, postResource)
export const deliveryDeclarationDetailResource = createResource(DELIVERY_DECLARATION_DETAIL)
export const deliverySerialNumberListResource = createResource(DELIVERY_SERIAL_NUMBER_LIST)
export const deliveryActsNotificationResource = createResource(DELIVERY_ACTS_NOTIFICATION)
export const deliveryFindDepartmentByIndexResource = createResource(DELIVERY_FIND_DEPARTMENT_BY_INDEX, postResource)

export const deliveryTicketListResource = friendlyUrlGenerator(
  [DELIVERY_TICKET_LIST, ''], baseResource, cabinetOrder
)

// export const deliveryRepairListResource = friendlyUrlGenerator(
//   [DELIVERY_REPAIR, ''], baseResource, cabinetOrder
// )

export const deliveryDeclarationUpdateListResource = friendlyUrlGenerator(
  [DELIVERY_DECLARATION_UPDATE_LIST, ''], baseResource, cabinetOrder
)
