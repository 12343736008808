<template lang="pug">
include /mixins.pug
+b.card
  +b.ds-panel.--space_xl
    +e.element.--offset_bottom
      +b.P.ds-caption.--size_md.--size_2md-md.--size_lg-xl.--variant_2 {{ _("Промокод") }}
      +b.ds-panel.--space_sm(v-if="cartInfo.promocode")
        +e.element.--offset_top
          +b.P.ds-caption.--size_xs.--color_green {{ _("Текущий промокод:") }} {{ cartInfo.promocodeName }}
  +b.VALIDATION-OBSERVER(
    @submit.prevent='prepareData'
    ref="validator"
    tag="form"
  )
    +b.g-row.--appearance_spaced.--space_xl
      +b.g-cell.g-cols.--12-xs
        d-control-static-input(
          name="promocode"
          v-model='formdata.promocode',
          type="text"
          :placeholder="_('Введите промокод')"
        )
      +b.g-cell.g-cols.--12-xs
        +b.BUTTON.control-button.--variant_transparent(@click.prevent="prepareData")
          +e.element
            +e.SPAN.text {{ _("Добавить") }}
</template>

<script>
import FormMixin, {
  defaultValidatorErrorsParser,
} from '@app/Forms/FormMixin'
import { cartPromocodeResource } from '@api/cart.service'
import { OpenMessageModal } from '@utils/submit'
import { resourceCaller } from '@resource/resourceCaller'

export default {
  props: {
    cartInfo: {},
  },

  mixins: [
    FormMixin,
  ],

  data() {
    return {
      formdata: {},
    }
  },

  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData() {
      const isValid = await this.$refs.validator.validate()
      if (!isValid) return

      if (this.formdata.promocode) {
        this.submit(isValid, this.formdata)
      } else {
        const text = this._('Введите промокод')
        this.$notify({ text, type: 'error' })
      }
    },

    async send(data) {
      const resource = await resourceCaller(cartPromocodeResource)
      return resource.execute({}, data).then(() => {
        const meta = {
          title: this._('Промокод успешно применен'),
        }
        OpenMessageModal(meta, this)
        this.$emit('promocode:updated')
      })
    },
  },
}
</script>
