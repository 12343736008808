/**
 * @param {object} data
 * @returns String of prettified address
 */
export const generateDepartmentLabel = data => {
  if (!data) return ''

  const { locality } = data

  if (!locality) return ''

  const { regionName, district, name } = locality
  const location = [
    data.name, name, district, regionName,
  ]
  return location.filter(el => el).join(', ')
}

/**
 * @param {object} data
 * @returns String of prettified address
 */
export const generateAddressLabel = data => {
  if (!data) return ''

  const { localityInfo: { regionName, district, name } } = data
  const { address, houseNumber, apartmentNumber } = data
  const location = [
    address, houseNumber, apartmentNumber,
    name, district, regionName,
  ]
  return location.filter(el => el).join(', ')
}
