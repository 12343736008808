<template lang="pug">
include /mixins.pug
+b.card.--space_1
  +b.ds-panel.--space_3xl
    +e.element.--offset_bottom
      +b.P.ds-caption.--size_md.--size_lg-md.--variant_2 {{ _("Получатель") }}
  +b.g-row.--appearance_spaced.--space_xl
    cell(
      v-for='field in fields'
      :cols='field.cols || "12-xs 6-sm"'
      :key='field.name'
    )
      tag(
        autocomplete="off"
        :tag='field.tag || "d-control-static-input"'
        :name='field.name'
        :rules='field.rules'
        :ref='field.name'
        :type='field.type || "text"'
        :input-label='field.label'
        :placeholder='field.placeholder'
        :mask="field.isMasked ? '+38 (999) 999 99 99' : ''"
        v-model='formdata[field.name]'
      )
</template>

<script>
export default {
  props: {
    formdata: {
      type: Object,
    },
  },

  data() {
    return {
      fields: [
        {
          name: 'lastName',
          rules: 'required|max:255',
          label: this._('Фамилия'),
        },
        {
          name: 'firstName',
          rules: 'required|max:255',
          label: this._('Имя'),
        },
        {
          name: 'patronymic',
          rules: 'required|max:255',
          label: this._('Отчество'),
        },
        {
          name: 'phone',
          rules: 'required|phone',
          label: this._('Телефон'),
          isMasked: true,
        },
      ],
    }
  },
}
</script>
