<template lang="pug">
include /mixins.pug
div
  +b.i-preloader.--variant_1.--variant_2.--absolute(:class="{'is-active': isLoad}")
    +e.item.--size_md
  +b.g-row.--appearance_spaced.--space_xl
    +b.g-cell.g-cols.--12-xs(
      :class="{'g-cols--6-sm g-cols--5-lg g-cols--4-xl': !fullWidth}"
    )
      d-control-static-multiselect(
        name="region"
        rules="required"
        :allow-empty="false"
        :searchable="true"
        :input-label="labels.region.label"
        :placeholder="labels.region.placeholder"
        :options="choices.region"
        v-model="formdata.region"
        label="name",
        :options-limit="6000"
        :max-height="190"
        @select="getLocalities"
        :loading="loading.region",
      )
        template(slot="noResult" slot-scope="{}")
          span {{ _("Элементы не найдены") }}
        template(slot="noOptions" slot-scope="{}")
          span {{ _("Список пуст") }}
    +b.g-cell.g-cols.--12-xs(
      :class="{'g-cols--6-sm g-cols--5-lg g-cols--4-xl': !fullWidth}"
    )
      autocomplete-select(
        ref="localityAutocomplete"
        :required="true"
        rules="required"
        :disabled="!formdata.region"
        :value="formdata.locality"
        :filter="autocompleteFilters.locality"
        v-model="formdata.locality"
        @input="getDepartments"
        @update="setFieldValue($event, 'locality')"
      )
    template(v-if="departmentIsVisible")
      +b.g-cell.g-cols.--12-xs(:class="{'g-cols--6-sm g-cols--5-lg g-cols--4-xl': !fullWidth}")
        autocomplete-select(
          ref="departmentAutocomplete"
          :required="true"
          rules="required"
          :disabled="!formdata.locality"
          :value="formdata.department"
          :filter="autocompleteFilters.department"
          v-model="formdata.department"
          @update="setFieldValue($event, 'department')"
        )
      +b.g-cell.g-cols.--12-xs(:class="{'g-cols--6-sm g-cols--5-lg g-cols--4-xl': !fullWidth}")
        +b.g-row.--appearance_spaced
          +b.g-cell.g-cols.--auto
            d-control-static-input(
              autocomplete="off"
              type='text'
              name='departmentIndex'
              :input-label='labels.departmentIndex.label'
              :placeholder='labels.departmentIndex.placeholder'
              v-model='departmentIndex'
              @input="$emit('input', formdata)"
            )
          +b.g-cell.g-cols.--narrow
            +b.ds-panel.--space_3xl
              +e.element.--offset_top
                +b.i-button(@click.prevent="findDepartment")
                  +e.wrapper
                    +e.icon
                      +b.I.icon-search
</template>

<script>
import { resourceCaller } from '@resource/resourceCaller'
import {
  deliveryRegionsResource,
  deliveryFindDepartmentByIndexResource,
  DELIVERY_LOCALITIES,
  DELIVERY_DEPARTMENTS,
} from '@api/delivery.service'
import AutocompleteSelect from '@app/Filters/Widgets/AutocompleteMultiselectWidget'

export default {
  props: {
    formdata: {
      type: Object,
    },
    departmentIsVisible: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AutocompleteSelect,
  },

  data() {
    return {
      choices: {
        region: [],
      },
      loading: {
        region: false,
      },
      labels: {
        region: {
          label: this._('Укажите область'),
          placeholder: this._('Область'),
        },
        departmentIndex: {
          label: this._('Укажите индекс отделения'),
          placeholder: this._('Индекс отделения'),
        },
      },
      autocompleteFilters: {
        locality: {
          choiceUrl: DELIVERY_LOCALITIES,
          name: 'locality',
          inputLabel: this._('Укажите город'),
          placeholder: this._('Город'),
          label: 'localityWithRegion',
          trackBy: 'id',
          multiple: false,
          maxHeight: 190,
          searchKey: 'search',
          minLength: 1,
        },
        department: {
          choiceUrl: DELIVERY_DEPARTMENTS,
          name: 'department',
          inputLabel: this._('Укажите отделение'),
          placeholder: this._('Отделение'),
          label: 'name',
          trackBy: 'id',
          multiple: false,
          maxHeight: 190,
          searchKey: 'search',
          minLength: 1,
        },
      },
      departmentIndex: null,
      isLoad: false,
    }
  },

  created() {
    this.getRegions()
  },

  methods: {
    async getRegions() {
      this.loading.region = true
      const resource = await resourceCaller(deliveryRegionsResource)
      const { data: { items } } = await resource.execute()

      this.choices.region = items
      this.loading.region = false
    },

    async getLocalities() {
      this.$nextTick(() => {
        this.formdata.locality = null
        this.formdata.department = null

        const params = { region: this.formdata.region.id }

        this.$refs.localityAutocomplete.receive(params)
      })
    },

    async getDepartments() {
      if (!this.departmentIsVisible || !this.formdata.locality) return

      this.$nextTick(() => {
        this.formdata.department = null

        const params = { locality: this.formdata.locality }

        this.$refs.departmentAutocomplete.receive(params)
      })
    },

    async findDepartment() {
      if (!this.departmentIndex) {
        this.$notify({ text: this._('Укажите индекс отделения'), type: 'error' })

        return
      }

      const formdata = { index: this.departmentIndex }
      const resource = await resourceCaller(deliveryFindDepartmentByIndexResource)

      this.isLoad = true

      resource.execute({}, formdata).then(res => {
        const { data: { item } } = res

        if (item) {
          this.setDepartment(item)
        } else {
          this.departmentNotFoundNotify()
        }
      }).catch(() => {
        this.departmentNotFoundNotify()
      }).finally(() => {
        this.isLoad = false
      })
    },

    async setDepartment(item) {
      const region = this.choices.region.find(el => el.id === item.locality.region)

      if (region) {
        this.formdata.region = region
      }

      this.$refs.localityAutocomplete.setValue(item.locality)
      this.$refs.departmentAutocomplete.setValue(item)
    },

    departmentNotFoundNotify() {
      this.$notify({ text: this._('Отделение не было найдено по указанному индексу'), type: 'error' })
    },

    setFieldValue(val, key) {
      this.$set(this.formdata, key, val)
    },
  },
}
</script>
