import {
  cartInfoResource,
  cartUpdateResource,
} from '@api/cart.service'
import { resourceCaller } from '@resource/resourceCaller'

export function createStore() {
  return {
    namespaced: true,

    state: {
      cart: {
        divisionOrder: [],
      },
    },

    actions: {
      async getCartState({ commit }) {
        const resource = await resourceCaller(cartInfoResource)
        const { data: { item } } = await resource.execute()

        commit('CHANGE_CART_STATE', item)
      },

      async updateCartState({ commit }, { formdata, title, vm }) {
        const resource = await resourceCaller(cartUpdateResource)

        await resource.execute({}, formdata).then(res => {
          const { data: { item } } = res

          vm.$notify(title.success)

          commit('CHANGE_CART_STATE', item)
        }).catch(() => {
          vm.$notify({ text: title.error, type: 'error' })
        })
      },
    },

    mutations: {
      CHANGE_CART_STATE(state, result) {
        if (result.divisionOrder) {
          state.cart = result
        } else {
          state.cart = {
            ...result,
            divisionOrder: [],
          }
        }
      },
    },
  }
}
