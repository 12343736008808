const isArray = item => Array.isArray(item)

const getErrorsList = (acc, errors, key) => {
  /*
    {
      "galleryItems":[
        {
          "image":[
            {
              "message":"Відправленні дані не є файл. Перевірте тип кодування у формі.",
              "reason":"invalid"
            }
          ]
        },
        {
          "image":[
            {
              "message":"Відправленні дані не є файл. Перевірте тип кодування у формі.",
              "reason":"invalid"
            }
          ]
        }
      ]
    }

    will be:

    'galleryItems_image_0': ['Відправленні дані не є файл. Перевірте тип кодування у формі.']
    'galleryItems_image_1': ['Відправленні дані не є файл. Перевірте тип кодування у формі.']
  */
  errors.forEach((el, i) => {
    Object.keys(el).forEach(k => {
      const [firstError] = el[k]
      // key - parent key
      // k - child key
      // i - index of child item
      acc[`${key}_${k}_${i}`] = [firstError.message]
    })
  })
  return acc
}

const messageGetter = (acc = {}, errors, key) => {
  errors.forEach(e => {
    // if item of error has nested errors
    if (!e.message) {
      acc = getErrorsList(acc, errors, key)
      return acc
    }
    // if item of error is object
    if (Array.isArray(acc[key])) {
      acc[key].push(e.message)
    } else {
      acc[key] = [e.message]
    }
    return acc
  })
  return acc
}

const parseNestedObjects = (acc = {}, obj, key) => {
  /*
    {
      "user": {
        "email": [
          {
            "message": "User with this email already exists.",
            "reason": "invalid"
          }
        ]
      }
    }

    will be:

    'user_email': ['User with this email already exists.']
  */
  Object.keys(obj).forEach(k => {
    const [firstError] = obj[k]
    acc[`${key}_${k}`] = [firstError.message]
  })
  return acc
}

export function defaultValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, key) => {
    if (isArray(errors[key])) {
      acc = messageGetter(acc, errors[key], key)
    } else {
      acc = parseNestedObjects(acc, errors[key], key)
    }
    return acc
  }, {})
}

export function getParsedErrors(e) {
  return e.json().then(body => {
    const errors = {}
    body.errors.forEach(error => {
      if ('request' === error.domain && error.state) {
        Object.assign(errors, error.state)
      }
    })
    const parsed = defaultValidatorErrorsParser(errors)
    return parsed
  })
}

export function getErrorField(errors, key) {
  const field = errors[key]
  if (field) {
    if (Array.isArray(field)) {
      const [error] = field
      return error
    }
    return field
  }
  return {}
}

export default {
  data() {
    return {
      data: {},
      isLoad: false,
    }
  },
  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400
        const serverError = 500
        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e
        }

        this.parseErrors(e)
      })
    },
    parseErrors(e) {
      if (!this.updateValidator) {
        return e
      }

      return e.json().then(body => {
        const errors = {}

        body.errors.forEach(error => {
          if ('request' === error.domain && error.state) {
            Object.assign(errors, error.state)
          }
        })

        this.updateValidator(errors, body.errors)

        return e
      })
    },
    async submit(valid, data, info) {
      if (!valid) {
        return Promise.reject()
      }

      if (this.isLoad) {
        return false
      }

      this.isLoad = true

      if (this.$recaptcha && this.captchaConfig && this.captchaConfig.isActive && this.captchaConfig.action) {
        await this.$recaptchaLoaded()

        const token = await this.$recaptcha(this.captchaConfig.action)

        data.recaptcha = token
      }

      return this.catchFormErrors(this.send(data, info))
        .finally(() => {
          this.isLoad = false
        })
    },
  },
}
