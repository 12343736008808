import BaseView from '@app/List/View'
import { withDefaultProps } from '@aspectus/vue-utils'
import {
  productsListResource,
  productsFilterResource,
  productsSpecialListResource,
  productsSpecialFilterResource,
} from '@api/catalog.service'
import {
  cabinetFilterResource,
} from '@api/common.service'

import RouterParametersController from './RouterParametersController'

const composer = (
  listUrl,
  filtersUrl,
  controllerName = 'Controller',
  filterName = 'DefaultFilter'
) => withDefaultProps({
  listResource: () => listUrl,
  filtersResource: () => filtersUrl,
  controllerName: () => controllerName,
  filterName: () => filterName,
})(BaseView)

const CatalogView = composer(
  productsListResource,
  productsFilterResource,
  'Controller',
  'DefaultFilter'
)

const CatalogSpecialView = composer(
  productsSpecialListResource,
  productsSpecialFilterResource,
  'Controller',
  'DefaultFilter'
)

const CabinetListView = composer(
  null,
  cabinetFilterResource,
  'ControllerCabinet',
  'CabinetFilter'
)

export default function plugin(Vue) {
  Vue.component('product-catalog-filters', CatalogView)
  Vue.component('product-catalog-special-filters', CatalogSpecialView)
  Vue.component('cabinet-list-view', CabinetListView)
  Vue.component('router-parameters-controller', RouterParametersController)
}
