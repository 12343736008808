<template lang="pug">
include /mixins.pug
+b.card
  +b.ds-panel.--space_xl
    +e.element.--offset_bottom
      +b.P.ds-caption.--size_md.--size_2md-md.--size_lg-xl.--variant_2 {{ _("Наложеный платеж") }}
  validation-provider(
    tag="div"
    v-slot="{ errors }"
    name="cashDelivery"
  )
    ui-switch(
      :label-left="_('Нет')"
      :label-right="_('Да')"
      :disabled="formdata.deliveryOption && !isNovaPoshta"
      v-model="formdata.cashDelivery"
    )
  +b.ds-panel.--space_xl(v-if="formdata.cashDelivery")
    +e.element.--offset_top
      +b.g-row.--appearance_spaced
        +b.g-cell.g-cols.--12-xs(v-for="division in cart.divisionOrder")
          +b.ds-panel.--space_sm
            +e.element.--offset_bottom
              +b.P.ds-caption.--size_sm.--bold {{ division.title }}:
          d-control-static-input(
            autocomplete="off"
            :name="'cashPayments_'+division.id"
            rules="required|numeric"
            type="number"
            :placeholder="labels.division"
            v-model.number="formdata.cashPayments[division.id].amount"
          )
</template>

<script>
export default {
  props: {
    formdata: {
      type: Object,
    },
    cart: {
      type: Object,
    },
    isNovaPoshta: {
      type: Boolean,
    },
  },

  data() {
    return {
      labels: {
        division: this._('Введите сумму'),
      },
    }
  },
}
</script>
