<template lang="pug">
  include /mixins
  +b.modal.--bg_cover(:style="'background-image: url(/static/img/message-bg.png)'")
    +b.close.--color_white(@click.prevent='$emit("close")')
      +b.I.icon-close
    +b.P.ds-caption.--color_white.--size_md.--size_lg-xl.--appearance_uppercase.--bold(
      v-if="meta.title"
    ) {{ meta.title }}
    +b.ds-panel--space_lg(v-if="meta.text")
      +e.element--offset_top
        +b.P.ds-caption.--color_white.--size_4xs.--size_xs-sm.--size_sm-lg {{ meta.text }}
</template>

<script>
export default {
  name: 'MessageModal',

  props: {
    meta: {
      type: Object,
    },
  },
}
</script>
