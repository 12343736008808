import { renderSlim } from '@aspectus/vue-utils'
import { Tag } from '@aspectus/vue-tag'

export default {
  name: 'user-access-state',
  props: {
    permissions: Array,
    permission: String,
    conjoined: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      hasAccess: false,
    };
  },

  mounted() {
    this.checkers()
  },

  methods: {
    checkers() {
      if (this.permission) {
        this.hasAccess = this.$userPermissions.includes(this.permission)
      }

      if (this.permissions) {
        const arr = this.permissions.reduce((acc, p) => {
          acc.push(this.$userPermissions.includes(p))
          return acc
        }, [])

        const method = this.conjoined ? 'every' : 'some'

        this.hasAccess = arr[method](el => el)
      }
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      hasAccess: this.hasAccess,
    }) || [], h, Tag)
  },
}
