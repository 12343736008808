<template lang='pug'>
  include /mixins
  +b.i-catalog
    +e.sidebar
      catalog-sidebar(
        :filters="filters"
        @update:filters="changeFilters"
        :pagination="pagination",
        :attributesResource='attributesResource',
        :additionalResource="additionalResource"
        :label='label'
        :totalItems="totalItems"
        :filterName="filterName"
      )
    +e.content
      +b.i-preloader.--variant_1.--variant_2.--absolute(:class="{'is-active': loading}")
        +e.item.--size_md
      +b.ds-panel.--space_lg
        +e.element.--offset_bottom
          +b.i-panel
            +b.g-row.--appearance_spaced.--justify_between
              +b.g-cell.g-cols.--5-xl.--6-hd
                +b.P.ds-caption.--size_xs.--size_sm-md.--bold {{ _("Всего товаров:") }}
                  |&nbsp;
                  +b.SPAN.ds-caption.--size_xs.--size_sm-md.--regular {{ totalItems }} {{ _("шт") }}
              +b.g-cell.g-cols.--7-xl.--6-hd
                +b.g-row.--appearance_spaced.--justify_between.--space_sm.--space_lg-hd
                  +b.g-cell.g-cols
                    +b.g-row.--appearance_spaced.--space_xl.--align_center
                      +b.g-cell
                        +b.P.ds-caption.--size_xs.--size_sm-md.--bold {{ _("Сортировать по:") }}
                      +b.g-cell
                        sorting-widget(
                          :value="filters.filters.ordering || 'default'",
                          :filters="filters"
                          @input="changeFilters"
                        )
                  +b.g-cell.g-cols.is-visible-sm
                    +b.g-row.--appearance_spaced.--space_sm.--space_lg-hd
                      +b.g-cell
                        +b.P.ds-caption.--size_xs.--size_sm-md.--bold {{ _("Отображать:") }}
                      +b.g-cell(v-for="view in viewTypes")
                        +b.i-link.--color_grey.--size_md.--size_lg-md(
                          :class="{'is-active': catalogViewType === view.key}"
                          @click.prevent="changeProductsView(view.key)"
                        )
                          +e.icon.--offset_none
                            +b.I(:class="view.icon")
      +b.g-row.--appearance_spaced.--space_lg-xl(v-if="result && result.items.length")
        +b.g-cell.g-cols.--12-xs(
          v-for="product in result.items"
          :class="{'g-cols--6-sm g-cols--4-lg g-cols--3-hd': catalogViewType === 'tile'}"
        )
          product-card(
            :key="product.id"
            :item="product"
            :is-catalog="true"
            :catalog-view-type="catalogViewType"
          )
      +b.i-panel(v-else)
        +b.ds-caption.--size_md.--size_lg-md.--variant_3 {{ _('Список товаров пуст') }}
      ui-limit-pagination(
        :pagination='pagination'
        @input='handlePaginate'
      )
</template>

<script>
import SortingWidget from '@app/Filters/Sorting'
import { setCookie, getCookie } from '@utils/cookies'
import CatalogSidebar from './Sidebar'

export default {
  components: {
    CatalogSidebar,
    SortingWidget,
  },

  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterName',
    'totalItems',
  ],

  data() {
    return {
      catalogViewType: 'tile',
      viewTypes: [
        {
          icon: 'icon-view1',
          key: 'tile',
        },
        {
          icon: 'icon-view2',
          key: 'wide',
        },
      ],
    }
  },

  created() {
    this.getData()

    /**
     * get catalog view type from cookie
     * then this type will be passed to the catalog as scoped slot
     */
    const view = getCookie('amt-current-view')
    if (view) this.catalogViewType = view
  },

  methods: {
    getData() {
      this.receive(this.parameters)
    },

    changeProductsView(key) {
      /**
       * set catalog view type to cookie
       */
      this.catalogViewType = key
      setCookie('amt-current-view', key)
    },

    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
  },
}

</script>
