<template lang="pug">
include /mixins.pug
+b.checkout(
  :class="{'is-active': isActive}"
)
  +b.i-preloader.--variant_1.--variant_2.--absolute(:class="{'is-active': isLoad}")
    +e.item.--size_md
  +b.blur(
    @click.prevent="hideOrder"
    :class="{'is-hidden': !isActive}"
  )
  +b.VALIDATION-OBSERVER(
    @submit.prevent='prepareData'
    ref="validator"
    tag="form"
  )
    +e.wrapper
      +e.content
        +b.g-row.--appearance_spaced.--space_2xl-xl
          +b.g-cell.g-cols.--12-xs
            +b.card.--space_1
              +b.ds-panel.--space_3xl
                +e.element.--offset_bottom
                  +b.g-row.--appearance_spaced.--align_center.--justify_between
                    +b.g-cell
                      +b.P.ds-caption.--size_md.--size_lg-md.--variant_2 {{ _("Список товаров") }}
                    +b.g-cell
                      +b.g-row.--appearance_spaced.--align_center.--justify_between
                        +b.g-cell(v-if="needRecalculatePrice && alreadyCalculated")
                          +b.P.ds-caption.--size_sm.--color_red {{ _("Пересчитайте стоимость") }}
                        +b.g-cell
                          +b.preloader-wrapper(:class="{ 'is-active': calculateLoading }")
                            +b.BUTTON.control-button.--variant_green(
                              @click.prevent="calcDealerPrice"
                            )
                              +e.element
                                +e.SPAN.text {{ _("Рассчитать оптовую стоимость") }}
                            +e.item(v-if="calculateLoading")
                              +b.i-preloader.--variant_1.--relative.is-active
                                +e.item.--size_sm
                    +b.g-cell.g-cols.--12-xs
                      +b.g-row.--appearance_spaced.--justify_end
                        +b.g-cell.g-cols.--12-xs.--6-sm.--5-md
                          +b.P.ds-caption.--size_xs.--size_2xs {{ _("*Расчет стоимости зависит от условий доставки и оплаты товара и может быть изменен при обработке заказа") }}
              +b.product-scroller
                +b.ds-panel(
                  v-for="(division, index) in cartInfo.divisionOrder"
                  :class="cartInfo.divisionOrder.length === index + 1 ? 'ds-panel--space_none' : 'ds-panel--space_sm'"
                )
                  +e.element.--offset_bottom
                    +b.ds-panel.--space_sm
                      +e.element.--offset_bottom
                        +b.P.ds-caption.--size_sm.--color_green.--bold {{ division.title }}
                    product-simple-card(
                      v-for="(item, index) in division.items"
                      :key="index"
                      :index="index"
                      :item="item"
                    )
          +b.g-cell.g-cols.--12-xs
            checkout-user-info(
              :formdata="formdata"
            )
          +b.g-cell.g-cols.--12-xs
            checkout-delivery(
              :formdata="formdata"
              :choices="choices"
              @delivery:add:address="addAddress"
              @delivery:change="deliveryChanged"
            )
          +b.g-cell.g-cols.--12-xs
            checkout-payment(
              :formdata="formdata"
              :choices="choices"
              @payment:change="paymentChanged"
            )
          +b.g-cell.g-cols.--12-xs
            checkout-comment(
              :formdata="formdata"
            )
      +e.aside(:class="{'is-active': isActive}")
        +b.close.is-hidden-xl(@click.prevent="hideOrder")
          +b.icon-close
        +b.g-row.--appearance_spaced.--space_2xl-xl
          +b.g-cell.g-cols.--12-xs
            checkout-promocode(
              :cart-info="cartInfo"
              @promocode:updated="getCartState"
            )
          +b.g-cell.g-cols.--12-xs
            checkout-delivery-cash(
              :formdata="formdata"
              :cart="cart"
              :is-nova-poshta="isNovaPoshta"
            )
          +b.g-cell.g-cols.--12-xs
            checkout-order(
              :formdata="formdata"
              :cart-info="cartInfo"
            )
  +e.button(:key="orderButtonKey")
    +b.BUTTON.control-button.--variant_green(@click.prevent="openOrder")
      +e.element
        +e.SPAN.text {{ _("Заказать") }} ({{ cartInfo.totalAmount }} {{ currentCurrency.currencyName }})
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { OpenMessageModal } from '@utils/submit'
import {
  orderOptionsResource,
  orderCreateResource,
  orderDealerPriceSetResource,
} from '@api/order.service'
import {
  userAddressListResource,
  userAddressAddResource,
} from '@api/profile.service'
import { clearPhone } from '@utils/transformers'
import { resourceCaller } from '@resource/resourceCaller'

// const PICKUP_TYPE = 1
const USER_DEPARTMENT_TYPE = 2
const USER_ADDRESS_TYPE = 3

export default {
  props: {
    successPageUrl: {
      type: String,
    },
  },

  mixins: [
    FormMixin,
  ],

  data() {
    return {
      isActive: false,
      formdata: {
        region: null,
        locality: null,
        department: null,
        address: null,
        cashDelivery: false,
        deliveryOption: null,
        cashPayments: {},
      },
      orderButtonKey: 0,
      cartInfo: {
        totalAmount: 0,
        divisionOrder: [],
      },
      choices: {
        deliveryOption: [],
        paymentType: [],
        address: [],
        conditionPayment: [],
      },
      needRecalculatePrice: false,
      calculateLoading: false,
      alreadyCalculated: false,
    }
  },

  computed: {
    ...mapState('catalog', [
      'currentCurrency',
    ]),

    ...mapState('users/auth', [
      'user',
    ]),

    ...mapState('cart', [
      'cart',
    ]),

    isNovaPoshta() {
      const values = [2, 3]
      const { deliveryOption } = this.formdata

      return values.includes(deliveryOption)
    },
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(nval) {
        if (nval && nval.info) {
          this.setUserInfo(nval.info)
        }
      },
    },

    cart: {
      deep: true,
      handler(nval) {
        if (nval) {
          this.setCartInfo(nval)
        }
      },
    },
  },

  created() {
    this.getChoices()
    this.getAddressList()
  },

  methods: {
    ...mapActions('cart', [
      'getCartState',
    ]),

    ...mapMutations('cart', [
      'CHANGE_CART_STATE',
    ]),

    async calcDealerPrice() {
      if (!this.formdata.deliveryOption) {
        const meta = {
          title: this._('Сначала выберите вариант доставки'),
        }
        OpenMessageModal(meta, this)

        return
      }

      if (this.calculateLoading) return

      this.calculateLoading = true

      const formdata = {
        deliveryOption: this.formdata.deliveryOption,
        conditionPayment: this.formdata.conditionPayment,
      }

      const resource = await resourceCaller(orderDealerPriceSetResource)

      resource.execute({}, formdata).then(res => {
        const { data: { item } } = res

        this.CHANGE_CART_STATE(item)

        this.needRecalculatePrice = false
        this.alreadyCalculated = true

        this.$notify({
          text: this._('Оптовая стоимость успешно расчитана'),
          type: 'success',
        })
      }).catch(() => {
        this.$notify({
          text: this._('Оптовая стоимость не была расчитана'),
          type: 'error',
        })
      }).finally(() => {
        this.calculateLoading = false
      })
    },

    paymentChanged() {
      this.needRecalculatePrice = true
    },

    deliveryChanged() {
      this.needRecalculatePrice = true

      this.$nextTick(() => {
        if (!this.isNovaPoshta) {
          this.formdata.cashDelivery = false
        }
      })
    },

    setCartInfo(nval) {
      this.cartInfo = JSON.parse(JSON.stringify(nval))

      // Need for fixing bug on ipad
      this.orderButtonKey++

      this.redirectFromCheckout()
      this.setUserInfo(this.user.info)
      this.preselectCashPayments()
      // this.preselectChoices()
    },

    redirectFromCheckout() {
      if (!this.cartInfo.divisionOrder.length) {
        window.location = window.indexPageUrl
      }
    },

    preselectCashPayments() {
      this.cartInfo.divisionOrder.forEach(division => {
        this.$set(this.formdata.cashPayments, division.id, {})
      })
    },

    async getChoices() {
      const resource = await resourceCaller(orderOptionsResource)

      resource.execute().then(res => {
        const { data: { item: { actions: { POST } } } } = res
        const { deliveryOption, conditionPayment } = POST

        this.choices.deliveryOption = deliveryOption.choices
        this.choices.conditionPayment = conditionPayment.choices

        const defaultPaymentCondition = conditionPayment.choices.find(el => 0 === el.value)

        if (defaultPaymentCondition) {
          this.formdata.conditionPayment = defaultPaymentCondition.value
        }

        // this.preselectChoices()
      })
    },

    async getAddressList() {
      const resource = await resourceCaller(userAddressListResource)

      await resource.execute().then(res => {
        const { data: { item } } = res
        this.choices.address = item
      })
    },

    /**
     * @param {object} data
     */
    setUserInfo(data) {
      const userFields = ['firstName', 'lastName', 'patronymic', 'phone']
      userFields.forEach(k => {
        if (data[k] && !this.formdata[k]) {
          this.$set(this.formdata, k, data[k])
        }
      })
    },

    /**
     * @param {object} data
     */
    async addAddress(data) {
      const formdata = {
        locality: data.locality,
        address: data.address,
        houseNumber: data.houseNumber,
        apartmentNumber: data.apartmentNumber,
      }
      const resource = await resourceCaller(userAddressAddResource)

      resource.execute({}, formdata).then(async res => {
        const { data: { item } } = res
        const meta = {
          title: this._('Адрес успешно добавлен в Ваш список адресов'),
        }
        OpenMessageModal(meta, this)

        await this.getAddressList()

        this.formdata.address = item
      })
    },

    toggleOverflow() {
      const element = document.querySelector('html')
      const { pageYOffset } = window

      if (this.isActive) {
        element.style.top = `-${pageYOffset}px`
        element.classList.add('is-overflow')
      } else {
        const scrollOffset = Math.abs(element.offsetTop)

        element.removeAttribute('style')
        element.classList.remove('is-overflow')

        window.scrollTo({
          top: scrollOffset,
          behavior: 'auto',
        })
      }
    },

    openOrder() {
      this.isActive = true
      this.toggleOverflow()
    },

    hideOrder() {
      this.isActive = false
      this.toggleOverflow()
    },

    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData() {
      const isValid = await this.$refs.validator.validate()
      if (!isValid) {
        const meta = {
          title: this._('Для оформления заказа проверьте все ли обязательные поля заполнены и не имеют ли ошибок'),
        }
        OpenMessageModal(meta, this)
        return
      }
      this.submit(isValid, this.formdata)
    },

    /**
     * @param {object} data
     */
    prepareFormdata(data) {
      const formdata = JSON.parse(JSON.stringify(data))

      formdata.phone = clearPhone(data.phone)

      const { deliveryOption } = formdata

      // if (PICKUP_TYPE === deliveryOption) {
      //   formdata.deliveryId = formdata.locality.id
      // }
      if (USER_DEPARTMENT_TYPE === deliveryOption) {
        formdata.deliveryId = formdata.department
      } else if (USER_ADDRESS_TYPE === deliveryOption) {
        formdata.deliveryId = formdata.address.id
      }

      if (formdata.cashDelivery) {
        formdata.cashPayments = Object.keys(formdata.cashPayments).reduce((acc, key) => {
          const { amount } = formdata.cashPayments[key]

          if (amount) {
            acc.push({ id: key, amount })
          }

          return acc
        }, [])
      } else {
        delete formdata.cashPayments
      }

      delete formdata.region
      delete formdata.locality
      delete formdata.department
      delete formdata.address

      return formdata
    },

    /**
     * @param {object} data
     */
    async send(data) {
      const formdata = this.prepareFormdata(data)
      const resource = await resourceCaller(orderCreateResource)

      return resource.execute({}, formdata).then(() => {
        window.location = this.successPageUrl
      }).catch(e => {
        e.json().then(body => {
          if (body.errors) {
            const errors = {}

            body.errors.forEach(error => {
              if ('request' === error.domain && error.state) {
                Object.assign(errors, error.state)
              }
            })

            this.updateValidator(errors, body.errors)
          } else if (body.error) {
            const meta = {
              title: body.error,
            }
            const closeEvent = () => {
              window.location = window.indexPageUrl
            }
            OpenMessageModal(meta, this, closeEvent)
          }
        })
      })
    },
  },
}
</script>
