<script>
import { mapActions, mapState } from 'vuex'
import {
  comparisonCreateResource,
  comparisonProductRemoveResource,
} from '@api/comparison.service'
import { getParsedErrors } from '@app/Forms/FormMixin'
import { resourceCaller } from '@resource/resourceCaller'

export default {
  name: 'CompareAddButton',

  props: {
    productId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isInCompare: false,
    }
  },

  computed: {
    ...mapState('catalog', [
      'comparison',
    ]),
  },

  watch: {
    comparison: {
      immediate: true,
      handler(nval) {
        this.updateCompareTrigger(nval)
      },
    },
  },

  methods: {
    ...mapActions('catalog', [
      'getComparisonInfo',
    ]),

    compare() {
      if (this.isInCompare) {
        this.removeProduct()
      } else {
        this.addProduct()
      }
    },

    async addProduct() {
      const formdata = { products: [this.productId] }
      const resource = await resourceCaller(comparisonCreateResource)
      resource.execute({}, formdata).then(() => {
        this.getComparisonInfo()
        this.isInCompare = true
        const info = {
          title: this._('Товар был добавлен в сравнение'),
        }
        this.openInfoModal(info)
      }).catch(async error => {
        const parsed = await getParsedErrors(error)
        const [errorMessage] = parsed.products
        this.$notify({
          text: errorMessage,
          type: 'error',
        })
      })
    },

    async removeProduct() {
      const params = { id: this.productId }
      const resource = await resourceCaller(comparisonProductRemoveResource)
      resource.execute(params).then(() => {
        this.getComparisonInfo()
        const info = {
          title: this._('Товар был удален из сравнения'),
        }
        this.openInfoModal(info)
        this.isInCompare = false
      })
    },

    updateCompareTrigger(data) {
      const products = data.reduce((acc, el) => {
        if (el.products) {
          acc.push(...el.products)
        }
        return acc
      }, [])

      if (products.includes(this.productId)) {
        this.isInCompare = true
      } else {
        this.isInCompare = false
      }
    },

    openInfoModal(info) {
      const modal = () => import('@app/Modals/Common/Info')
      this.$modal.show(modal, {
        info,
        timeout: 1500,
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      compare: this.compare,
      isInCompare: this.isInCompare,
    })
  },
}
</script>
