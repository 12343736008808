import { accessMeta } from '@md/permissions'
import { TYPES } from '@md/users/constances'

export const REDIRECTS = {
  [TYPES.DEALER]: 'profile:orders:list',
  [TYPES.SALESMAN]: 'profile:coupon:create',
  [TYPES.OPERATOR_DEALER]: 'profile:users:dealer:list',
  [TYPES.OPERATOR_SALESMAN]: 'profile:users:seller:list',
  [TYPES.PARTNER]: 'profile:repair:list',
}

const role = Number(window.role)

const initialRoute = { name: REDIRECTS[role] }

export const permissionChecker = perms => perms.includes(role)

export const accessPermission = (permissions, options) => accessMeta(
  permissionChecker(permissions), initialRoute, options
)
