/* eslint-disable */
import * as rules from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import {
  required, confirmed, min, min_value as minValue,
  max, max_value as maxValue,
} from 'vee-validate/dist/rules'

for (const rule in rules) {
  extend(rule, rules[rule])
}

export const createCustomRules = app => {
  extend('numeric', { ...required, message: app._('Поле должно быть числом.') });
  extend('required', { ...required, message: app._('Это поле обязательно к заполнению.') });
  extend('confirmed', { ...confirmed, message: app._('Пароли должны совпадать.') });
  extend('min', { ...min, message: (e, a) => `${app._('Количество символов должно быть не меньше')} ${a.length}` })
  extend('min_value', { ...minValue, message: app._('Минимальное значение {min}.') });
  extend('max', { ...max, message: (e, a) => `${app._('Количество символов не должно превышать')} ${a.length}` })
  extend('max_value', { ...maxValue, message: app._('Максимальное значение {max}.') });

  extend('emailMethod', {
    validate(value) {
      return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ\+0-9_.-]{2,64}@[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ0-9_.-]+[.a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ]{0,6}$/.test(value)
    },
    message() {
      return app._('Поле должно быть действительным электронным адресом.')
    },
  })

  extend('phone', {
    validate(value) {
      return /^[+() -\d]+$/.test(value)
    },
    message: app._('Телефон недействителен'),
  })

  extend('warrantyCard', {
    validate(value) {
      return /^[\d]{9}$/.test(value)
    },
    message: app._('Введите корректный серийный номер товара'),
  })

  extend('warrantyNumber', {
    validate(value) {
      return /^[\d]{10}$/.test(value)
    },
    message: app._('Введите корректный номер гарантийного талона'),
  })

  extend('cardMethod', {
    validate(value) {
      const replacedValue = value.replace(/\D/g, '')
      return /^\d{16}$/g.test(replacedValue)
    },
    message: app._('Введите полный номер карты'),
  })

  extend('documentDigitalPassport', {
    validate(value) {
      return /^[0-9]{9}$/.test(value)
    },
    message: app._('Введите номер паспорта'),
  })

  extend('documentPassport', {
    validate(value) {
      return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ]{2}[0-9]{6}$/.test(value)
    },
    message: app._('Введите серию и номер паспорта'),
  })

  extend('documentINN', {
    validate(value) {
      const replacedValue = value.replace(/\D/g, '')

      return /^\d{10}$/g.test(replacedValue)
    },
    message: app._('Введите полный ИИН'),
  })
}
