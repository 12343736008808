<template lang="pug">
  include /mixins
  +b.LABEL.pointer
    +b.g-row.--appearance_spaced.--justify_between
      +b.g-cell
        +b.P.ds-caption.--bold {{ filter.name }}
      +b.g-cell
        +b.d-control-checkbox.--variant_flex
          +e.INPUT.element(
            type='checkbox'
            v-model='checked'
            @change='submit'
          )
          +e.label
</template>
<script>
export default {
  name: 'SingleCheckboxWidget',

  props: ['value', 'filter'],

  data() {
    return {
      checked: false,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(nval) {
        this.checked = nval
      },
    },
  },

  methods: {
    submit() {
      this.$emit('input', this.checked || '')
    },
  },
}
</script>
