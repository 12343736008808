import {
  baseResource,
  createResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'
import { friendlyUrlGenerator } from '@utils/friendly'

const MODEL_PREFIX = 'catalog'

const CATALOG_BRAND_LIST = prefixAPI('/brands/', MODEL_PREFIX)
const PRODUCT_SLIDER_LIST = prefixAPI('/index_products/{key}/', MODEL_PREFIX)

const CATEGORIES_LIST = prefixAPI('/categories/', MODEL_PREFIX)
const SEACH_LIST = prefixAPI('/search/{search}/', MODEL_PREFIX)
const SEACH_BY_VENDOR = prefixAPI('/search_by_vendor_code/{search}/', MODEL_PREFIX)
const PRODUCT_DETAIL = prefixAPI('/product/{product}/', MODEL_PREFIX)
const RELATED_PRODUCTS = prefixAPI('/related_products/{id}/', MODEL_PREFIX)
export const AUTOCOMPLETE_PRODUCTS_URL = prefixAPI('/search_product/{query}/{?page}', MODEL_PREFIX)

const PRODUCTS_FILTERS_URL_PREFIX = prefixAPI('catalog/filter-catalog-values')
const PRODUCTS_FILTERS_URL_POSTFIX = ''

const PRODUCTS_LIST_URL_PREFIX = prefixAPI('catalog/products')
const PRODUCTS_LIST_URL_POSTFIX = ''

const PRODUCTS_SPECIAL_FILTERS_URL_PREFIX = prefixAPI('catalog/filter-values')
const PRODUCTS_SPECIAL_FILTERS_URL_POSTFIX = ''

const PRODUCTS_SPECIAL_LIST_URL_PREFIX = prefixAPI('catalog')
const PRODUCTS_SPECIAL_LIST_URL_POSTFIX = ''

const order = {
  path: [
    'prefix',
    'label',
    'postfix',
  ],
  query: [
    'query',
  ],
}

export const productsFilterResource = friendlyUrlGenerator(
  [PRODUCTS_FILTERS_URL_PREFIX, PRODUCTS_FILTERS_URL_POSTFIX], baseResource, order
)

export const productsListResource = friendlyUrlGenerator(
  [PRODUCTS_LIST_URL_PREFIX, PRODUCTS_LIST_URL_POSTFIX], baseResource, order
)

export const productsSpecialFilterResource = friendlyUrlGenerator(
  [PRODUCTS_SPECIAL_FILTERS_URL_PREFIX, PRODUCTS_SPECIAL_FILTERS_URL_POSTFIX], baseResource, order
)

export const productsSpecialListResource = friendlyUrlGenerator(
  [PRODUCTS_SPECIAL_LIST_URL_PREFIX, PRODUCTS_SPECIAL_LIST_URL_POSTFIX], baseResource, order
)

export const catalogBrandListResource = createResource(CATALOG_BRAND_LIST)
export const productSliderResource = createResource(PRODUCT_SLIDER_LIST)
export const categoriesListResource = createResource(CATEGORIES_LIST)
export const productDetailResource = createResource(PRODUCT_DETAIL)
export const searchResource = createResource(SEACH_LIST)
export const searchByVendorResource = createResource(SEACH_BY_VENDOR)
export const relatedProductsResource = createResource(RELATED_PRODUCTS)
export const autocompleteProductsResource = createResource(AUTOCOMPLETE_PRODUCTS_URL)
