<template lang="pug">
  include /mixins
  ui-modal-trigger(
    url='Modals/Common/Compare',
    v-slot="{ openPopup }"
  )
    +b.i-circle.--interactive.--variant_1(
      :title="hint"
      @click.prevent="openPopup"
    )
      +b.I.icon-compare
      +b.i-count.--variant_absolute.--variant_1
        +e.SPAN.content {{ totalCount }}
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      hint: this._('Сравнение'),
    }
  },

  async created() {
    if (window.isAuthenticated) {
      this.getComparisonInfo()
    }
  },

  computed: {
    ...mapState('catalog', [
      'comparison',
    ]),

    totalCount() {
      return this.comparison.reduce((acc, el) => {
        acc += el.productCount
        return acc
      }, 0)
    },
  },

  methods: {
    ...mapActions('catalog', [
      'getComparisonInfo',
    ]),
  },
}
</script>
