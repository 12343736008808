<template lang="pug">
  include /mixins
  d-control-static-input(
    class="d-control-input--variant_5"
    v-model='query'
    prepend-icon="icon-search"
    :name='query'
    autocomplete='off'
    label='caption'
    type='search'
    :rules="rules"
    :input-label="inputLabel",
    @input='searchDebounceFn'
    @keypress.13.prevent='searchDebounceFn'
  )
</template>
<script>
import { isEmpty } from '@aspectus/vue-utils'
import { debounce } from 'lodash'

export default {
  name: 'SearchWidget',
  props: ['value', 'label', 'rules'],
  data() {
    return {
      query: '',
      searchDebounceFn: null,
      inputLabel: this.label || this._('Пошук'),
    }
  },

  watch: {
    value(nval) {
      this.query = this.normalizeFrom(nval)
    },
  },

  mounted() {
    this.query = this.normalizeFrom(this.value)

    const timeout = 500
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.searchDebounceFn = debounce(this.submit, timeout)
  },

  methods: {
    submit() {
      const length = 3
      const zero = 0
      if (this.query.length >= length) {
        this.$emit('input', this.query)
        return
      }
      if (this.query.length === zero) {
        this.$emit('input', '')
      }
    },

    normalizeFrom(value) {
      if (isEmpty(value)) return ''
      const query = value
      return query
    },
  },
}
</script>
