<template lang="pug">
  include /mixins.pug
  +b.SECTION.ds-section.--space_sm.--space_md-md
    +e.element.--offset_vertical
      +b.g-container.--fit_hd
        +b.ds-panel.--space_xl.--space_2xxl-md
          +e.element.--offset_bottom
            +b.g-row.--align_center.--justify_between.--space_xl-xl.--appearance_spaced
              +b.g-cell.g-cols.--12-xs.--5-sm.--6-md
                +b.H1.ds-caption.--size_md.--size_lg-md.--variant_2 {{ _("Сравнение товаров") }}
              +b.g-cell.g-cols.--12-xs.--7-sm.--6-md
                +b.g-row.--appearance_spaced.--justify_end
                  +b.g-cell.g-cols.--6-xs.--4-lg.--3-xl
                    +b.BUTTON.control-button.--variant_transparent(@click.prevent="historyBack")
                      +e.element
                        +e.SPAN.text {{ _("Назад") }}
                  +b.g-cell.g-cols.--6-xs.--4-lg.--3-xl
                    +b.A.control-button(:href="indexPageUrl")
                      +e.element
                        +e.SPAN.text {{ _("На главную") }}
        +b.card
          +b.g-row.--align_center.--justify_between.--space_xl.--appearance_spaced(v-if="currentCategoryItem && currentCategoryItem.category")
            +b.g-cell
              +b.H2.ds-caption.--size_2lg.--appearance_uppercase.--bold {{ currentCategoryItem.category.name }}
            +b.g-cell
              +b.A.i-link.--color_green.--size_sm(:href="currentCategoryItem.category.url")
                +e.SPAN.text {{ _("Добавить еще товары") }}
                +e.icon.--offset_left
                  +b.I.icon-plus
          template(v-if="products.length < 2")
            template(v-if="products.length === 1")
              +b.ds-panel.--space_lg
                +e.element.--offset_vertical
                  +b.P.ds-caption.--size_md.--size_lg-md.--bold.--color_main {{ _("Недостаточно товаров для сравнения.") }}
              +b.g-row.--space_xl.--appearance_spaced
                +b.g-cell.g-cols.--flex_none.--6-sm.--4-lg.--3-xl
                  +b.relative
                    product-card.border-relief-4(
                      :item="products[0]"
                      :is-compare="true"
                    )
                    compare-remove-button(:product-id="products[0].id")
            +b.P.ds-caption.--size_lg.--size_xl-md.--bold(v-else) {{ _("Нет товаров в сравнении.") }}
          +b.ds-panel.--space_2lg.--space_xl-xl(v-else)
            +e.element.--offset_top
              +b.compare
                +e.mobile(v-if="!isDesktop")
                  +e.switch
                    +b.ds-panel.--space_2lg
                      +e.element.--offset_bottom
                        +b.card(ref="toggler")
                          +b.g-row.--justify_center
                            +b.g-cell
                              ui-switch(
                                :label-left="_('Все параметры')"
                                :label-right="_('Только отличия')"
                                @input="attributesDiffTrigger"
                                v-model="isDiffActive"
                              )
                  +e.content(ref="stickyWrapper")
                    +e.products(ref="stickyRow")
                      +b.g-row.--appearance_spaced.--appearance_nowrap.--space_none
                        +b.g-cell.g-cols.--flex_none.--10-xs.--5-sm.--3-half-lg(
                          v-for="product in products"
                        )
                          +b.relative
                            product-card.product--variant_1.product--bg_white.border-relief-4(
                              :item="product"
                              :is-compare="true"
                              :is-slider="true"
                            )
                            compare-remove-button(:product-id="product.id")
                    template(v-for="(attrs, attrIndex) in attributesLocal")
                      +b.ds-panel.--space_xs.sticky
                        +e.element.--offset_vertical
                          +b.P.ds-caption.--size_xs.--size_2sm-xl.--appearance_center.--bold {{ attrs[0].property.name }}
                      +b.g-row.--appearance_spaced.--appearance_nowrap.--space_none
                        +b.g-cell.g-cols.--flex_none.--10-xs.--5-sm.--3-half-lg(
                          v-for="product in products"
                        )
                          +b.ds-panel.--space_sm.align-height.border-relief-4
                            +e.element.--offset_full.align-height
                              template(
                                v-for="attr in attrs"
                                v-if="attr.productId === product.id"
                              )
                                +b.P.ds-caption.--size_xs.--size_2sm-lg(
                                ) {{ attr.value ? attr.value.value : '-' }}
                +e.desktop(v-if="isDesktop")
                  +b.ds-panel.--space_2lg.--space_xl-xl
                    +e.element.--offset_bottom
                      +b.g-row.--space_none.--justify_end
                        +b.g-cell
                          +b.nav-arrow.--prev.js-compare-prev
                            +b.I.icon-arrow-left
                        +b.g-cell
                          +b.nav-arrow.--next.js-compare-next
                            +b.I.icon-arrow-right
                  +e.wrapper(ref="stickyWrapper")
                    +e.aside
                      +b.g-row.--space_none
                        +b.g-cell.g-cols.--12-xs
                          +b.align-height.--variant_3.border-relief-4(
                            :style="{'height': headingHeight}"
                          )
                            +b.compare__switch.--desktop(ref="stickySwitch")
                              ui-switch.g-row--appearance_column(
                                :label-left="_('Все параметры')"
                                :label-right="_('Только отличия')"
                                @input="attributesDiffTrigger"
                                v-model="isDiffActive"
                              )
                        +b.g-cell.g-cols.--12-xs
                          +b.TABLE.i-table.--fullwidth
                            +e.TBODY.body
                              +e.TR.row(
                                v-for="(attrs, index) in attributesLocal"
                                ref="navRows"
                                :style="{'height': `${rowHeight[index]}px`}"
                              )
                                +e.TD.cell
                                  +b.P.ds-caption.--size_xs.--size_2sm-lg.fw-bold(
                                  ) {{ attrs[0].property.name }}
                    +e.content
                      ui-slider(:options="sliderOptions")
                        template(slot="slide")
                          swiper-slide.swiper-slide--d_block(
                            v-for="product in products"
                            :key="'product_' + product.id"
                          )
                            +b.g-row.--space_none
                              +b.g-cell.g-cols.--12-xs
                                +b.compare__products(ref="stickyRow")
                                  product-card.product--bg_white.border-relief-4(
                                    ref="heading"
                                    :style="{'height': headingHeight, 'boxSizing': 'border-box'}"
                                    :item="product"
                                    :is-compare="true"
                                    :is-slider="true"
                                  )
                                  compare-remove-button(:product-id="product.id")
                              +b.g-cell.g-cols.--12-xs
                                +b.TABLE.i-table.--fullwidth
                                  +e.TBODY.body
                                    +e.TR.row(
                                      v-for="(attrs, index) in attributesLocal"
                                      :ref="'productRows_' + index"
                                      :style="{'height': `${rowHeight[index]}px`}"
                                    )
                                      +e.TD.cell
                                        template(v-for="attr in attrs")
                                          +b.P.ds-caption.--size_xs.--size_2sm-lg(
                                            v-if="attr.productId === product.id"
                                          ) {{ attr.value ? attr.value.value : '-' }}
</template>

<script>
import {
  comparisonDetailResource,
} from '@api/comparison.service'
import { mapActions, mapState } from 'vuex'
import { isEqual } from 'lodash'
import { resourceCaller } from '@resource/resourceCaller'
import { DESKTOP_MEDIA } from '@utils/constances'

export default {
  name: 'CompareView',

  props: {
    currentCategoryId: {
      type: Number,
    },
  },

  data() {
    return {
      isDesktop: false,
      isDiffActive: false,
      isMounted: true,
      headingHeight: 'auto',
      rowHeight: {},
      attributesOriginal: [],
      indexPageUrl: window.indexPageUrl,
      attributesLocal: [],
      categories: [],
      products: [],
      sliderOptions: {
        slidesPerView: 5,
        spaceBetween: 0,
        freeMode: true,
        navigation: {
          prevEl: '.js-compare-prev',
          nextEl: '.js-compare-next',
        },
        breakpoints: {
          930: {
            slidesPerView: 2,
          },
          1199: {
            slidesPerView: 3,
          },
          1679: {
            slidesPerView: 4,
          },
        },
      },
    }
  },

  computed: {
    ...mapState('catalog', [
      'comparison',
    ]),

    currentCategoryItem() {
      return this.comparison.reduce((acc, el) => {
        if (el.id === this.currentCategoryId) {
          acc = el
        }
        return acc
      }, {})
    },
  },

  watch: {
    comparison: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.isMounted) {
          this.getData()
        }
      },
    },
  },

  created() {
    this.getData()
    this.checkDeviceType()
  },

  mounted() {
    const timeout = 700
    setTimeout(() => {
      this.renderComparePage()
    }, timeout)
  },

  methods: {
    ...mapActions('catalog', [
      'getComparisonInfo',
    ]),

    async getData() {
      const params = { id: this.currentCategoryId }
      const resource = await resourceCaller(comparisonDetailResource)
      resource.execute(params).then(res => {
        const { data: { item } } = res
        this.setData(item)
        this.isMounted = false
      }).catch(() => {
        window.location = this.indexPageUrl
      })
    },

    historyBack() {
      window.history.back()
    },

    setData(item) {
      const timeout = 700
      const { products } = item

      /**
       * created an array of all properties of products
       */
      const nonGroupedArr = products.reduce((acc, el) => {
        el.productProperties = el.productProperties.map(prop => {
          prop.productId = el.id
          acc.push(prop)
          return prop
        })
        return acc
      }, [])

      /**
       * grouping properties by property identifier
       */
      const groupedObj = nonGroupedArr.reduce((acc, el) => {
        const propertyId = el.property.id
        if (acc[propertyId]) {
          acc[propertyId].push(el)
        } else {
          acc[propertyId] = [el]
        }
        return acc
      }, {})

      /**
       * prettify attributes
       */
      const groupedArr = Object.values(groupedObj).map(el => {
        /**
         * get an array of product IDs from existing attributes
         */
        const productIds = el.reduce((acc, attr) => {
          acc.push(attr.productId)
          return acc
        }, [])
        products.forEach(product => {
          /**
           * if product has no an attribute
           * we adding default value for this attribute
           */
          if (!productIds.includes(product.id)) {
            const obj = {
              value: {
                value: '-',
              },
              productId: product.id,
            }
            el.push(obj)
          }
        })
        return el
      })

      this.attributesOriginal = groupedArr
      this.attributesLocal = JSON.parse(JSON.stringify(this.attributesOriginal))

      this.products = products

      setTimeout(() => {
        this.renderComparePage()
      }, timeout)
    },

    renderComparePage() {
      const one = 1
      if (this.products.length > one) {
        this.calcHeadingHeight()
        this.calcRowHeight()
        this.resizeEvent()
        this.scrollEvent()
      }
    },

    resizeEvent() {
      window.addEventListener('resize', () => {
        this.checkDeviceType()
        this.calcRowHeight()
        this.calcHeadingHeight()
      })
    },

    checkDeviceType() {
      this.isDesktop = window.innerWidth >= DESKTOP_MEDIA
    },

    calcHeadingHeight() {
      if (this.isDesktop) {
        this.headingHeight = 'auto'

        this.$nextTick(() => {
          /**
           * get array of heading elements
           */
          const items = this.$refs.heading
          const heightArr = []

          /**
           * add offset height of all heading elements to array
           */
          items.forEach(el => {
            heightArr.push(el.$el.offsetHeight)
          })

          /**
           * get max height from an array
           * for example this height used for
           * set the height of the switch heading
           */
          this.headingHeight = `${Math.max(...heightArr)}px`
        })
      }
    },

    calcRowHeight() {
      let heightArr = []
      this.rowHeight = {}
      this.$nextTick(() => {
        /**
         * get array of nav rows
         */
        const { navRows } = this.$refs

        if (navRows) {
          navRows.forEach((el, index) => {
            /**
             * get array of attribute cells
             */
            const productRows = this.$refs[`productRows_${index}`]
            if (!productRows) return
            /**
             * unshift cell of attribute heading
             */
            productRows.unshift(el)
            heightArr.push(productRows)
          })

          /**
           * generate array of arrays with cell heights
           */
          heightArr = heightArr.map(chunk => {
            chunk = chunk.map(el => {
              el = el.offsetHeight
              return el
            })
            return chunk
          })

          /**
           * get the max height of the attribute row
           */
          heightArr = heightArr.reduce((acc, el) => {
            acc.push(Math.max(...el))
            return acc
          }, [])

          /**
           * set the max height of the attribute row
           * for all cells in current attribute
           */
          heightArr.forEach((el, index) => {
            this.$set(this.rowHeight, index, el)
          })
        }
      })
    },

    getImageParams() {
      const firstImage = document.querySelector('.product__image')
      const firstImageHeight = firstImage.clientHeight
      if (!this.isDesktop) {
        return 0
      }
      return { height: firstImageHeight }
    },

    scrollEvent() {
      const zero = 0
      let desktopImageHeight = 0

      window.addEventListener('scroll', () => {
        this.calcHeadingHeight()
        /**
         * get image height for calc starting point of sticky effect
         */
        const imageParams = this.getImageParams()
        /**
         * if the sticky effect is activated images are hidden
         * and their client height is zero
         */
        if (imageParams.height) {
          desktopImageHeight = imageParams.height
        }

        const { toggler, stickyWrapper, stickyRow, stickySwitch } = this.$refs
        const { offsetHeight: mobileSwitchHeight = 0 } = toggler || {}
        const { top } = stickyWrapper.getBoundingClientRect()
        const bottom = stickyWrapper.offsetHeight

        /**
         * get absolute offset from the top of the sticky wrapper
         */
        const absTop = Math.abs(top)
        /**
         * on the mobile we have sticky row as single element
         * on the desktop we have sticky row as an array of element
         */
        const stickyRows = Array.isArray(stickyRow) ? stickyRow : [stickyRow]

        /**
         * push switch element to add sticky effect to an array of sticky rows
         * stickySwitch only exist on the desktop
         */
        if (stickySwitch) {
          stickyRows.push(stickySwitch)
        }

        /**
         * calculate scroll offset
         */
        let offset = absTop + mobileSwitchHeight

        /**
         * on desktop mobileSwitchHeight is zero
         * on mobile desktopImageHeight is zero
         *
         * add sticky effect if cumm of offset top of sticky wrapper
         * and desktopImageHeight (on mobile is zero)
         * greater than mobileSwitchHeight (on desktop is zero)
         */
        if (top + desktopImageHeight < mobileSwitchHeight) {
          /**
           * on the mobile switch element also is sticky
           * and if offset top of sticky wrapper is greater than zero
           * we substract from the height of mobile switch
           * the offset of the top of the sticky wrapper
           * for correct scrolling
           * because if don't the sticky row
           * will be move down not to the top
           */
          if (top > zero) {
            offset = mobileSwitchHeight - top
          }
          /**
           * if sum of the client height of the first sticky row
           * and bottom position of sticky wrapper greater than
           * absolute offset top of sticky wrapper
           * we add a sticky effect
           */
          const [firstRow] = stickyRows
          if (bottom - firstRow.clientHeight > absTop) {
            stickyRows.forEach(row => {
              row.style.top = `${offset - desktopImageHeight}px`
              stickyWrapper.style.paddingTop = `${desktopImageHeight}px`
              row.classList.add('is-sticky')
            })
          }
        } else {
          /**
           * remove sticky effect from rows
           */
          stickyRows.forEach(row => {
            row.style.top = 'auto'
            stickyWrapper.style.paddingTop = '0px'
            row.classList.remove('is-sticky')
          })
        }
      })
    },

    attributesDiffTrigger() {
      if (!this.isDiffActive) {
        this.attributesLocal = this.attributesOriginal
      } else {
        this.attributesLocal = this.attributesLocal.reduce((acc, el) => {
          if (!this.attrsIsEqual(el)) {
            acc.push(el)
          }
          return acc
        }, [])
      }

      this.calcRowHeight()
    },

    attrsIsEqual(attrs) {
      let preparedArr = JSON.parse(JSON.stringify(attrs))

      preparedArr = preparedArr.reduce((acc, el) => {
        const attrId = el.value ? el.value.id : null
        acc.push(attrId)
        return acc
      }, [])

      const [base] = preparedArr

      preparedArr = preparedArr.reduce((acc, item, index, array) => {
        if (!array.every(v => isEqual(v, base))) {
          acc.push(item)
        }
        return acc
      }, [])

      if (!preparedArr.length) return true
      return false
    },

  },
}
</script>
