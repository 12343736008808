import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const Display = () => import('./views/Display')

const ROUTE_NAME = 'profile:users:'

const p = name => ROUTE_NAME + name

const LIST = p('seller:list')
const DISPLAY = p('seller:display')

export function createRoutes(options) {
  return [
    route('seller/list/', List, LIST, {
      meta: {
        label: 'USERS_SELLER_LIST',
        accessConfig: accessPermission([TYPES.OPERATOR_SALESMAN], options),
      },
    }),
    route('seller/:id/', Display, DISPLAY, {
      meta: {
        label: 'USERS_SELLER_SETTINGS',
        accessConfig: accessPermission([TYPES.OPERATOR_SALESMAN], options),
      },
      props: true,
    }),
  ]
}
