import Message from '@app/Modals/Common/Message'
import Confirm from '@app/Modals/Common/Confirm'

/**
 * @argument {object} meta
 * @argument {object} self
 * @argument {function} closeEvent
 */
export const OpenMessageModal = (meta, self, closeEvent) => {
  self.$emit('close')
  const timeout = 200
  setTimeout(() => {
    self.$modal.show(Message, { meta }, {}, {
      'before-close': () => {
        if (closeEvent) {
          closeEvent()
        }
      },
    })
  }, timeout)
}

/**
 * @argument {object} meta
 * @argument {object} self
 */
export function confirmWithPromise(meta, self) {
  return new Promise(resolve => {
    /**
     * @argument {boolean} arg
     */
    const promise = arg => new Promise(res => {
      if (arg) {
        res(arg)
      }
    }).then(result => {
      resolve(result)
    })
    self.$modal.show(Confirm, {
      meta,
      promise,
    }, {})
  })
}
