import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const Display = () => import('./views/Display')

const ROUTE_NAME = 'profile:users:'

const p = name => ROUTE_NAME + name

const LIST = p('dealer:list')
const DISPLAY = p('dealer:display')

export function createRoutes(options) {
  return [
    route('dealer/list/', List, LIST, {
      meta: {
        label: 'USERS_DEALER_LIST',
        accessConfig: accessPermission([TYPES.OPERATOR_DEALER], options),
      },
    }),
    route('dealer/:id/', Display, DISPLAY, {
      meta: {
        label: 'USERS_DEALER_SETTINGS',
        accessConfig: accessPermission([TYPES.OPERATOR_DEALER], options),
      },
      props: true,
    }),
  ]
}
