import { withDefaultProps } from '@aspectus/vue-utils'
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'

import BoundLabel from './BoundLabel'
import ErrorComponent from './ErrorComponent'
import MultiSelect from './Multiselect'
import Datepicker from './DatePicker'
import StaticLabel from './StaticLabel'
import UnifiedControlElement from './UnifiedControlDescriptor'
import PrependComponent from './PrependComponent'
import AppendComponent from './AppendComponent'

const composerForInput = (input, label = BoundLabel, base = UnifiedControlElement) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
  prependComponent: () => PrependComponent,
  appendComponent: () => AppendComponent,
})(base)

const composer = input => withDefaultProps({
  element: () => input,
  labelComponent: null,
  errorComponent: () => ErrorComponent,
  bails: true,
  controllerComponent: () => NoneController,
  prependComponent: () => PrependComponent,
  appendComponent: () => AppendComponent,
})(Descriptor)

// input with static input
const InputWithStaticLabel = composerForInput('Input', StaticLabel)
const ControlStaticInput = composer(InputWithStaticLabel)

// multiselect with static input
const MultiselectStaticLabel = composerForInput('MultiSelect', StaticLabel)
const ControlStaticMultiselect = composer(MultiselectStaticLabel)

// textarea with static input
const TextareaStaticLabel = composerForInput('Textarea', StaticLabel)
const ControlStaticTextarea = composer(TextareaStaticLabel)

// flatpickr with static input
const DatePickerStaticLabel = composerForInput('Datepicker', StaticLabel)
const ControlStaticDatePicker = composer(DatePickerStaticLabel)

export default function install(Vue, { prefix = 'DControl' } = {}) {
  Vue.component('MultiSelect', MultiSelect)
  Vue.component('Datepicker', Datepicker)
  Vue.component('BoundLabel', BoundLabel)
  Vue.component('ErrorComponent', ErrorComponent)
  Vue.component(`${prefix}StaticInput`, ControlStaticInput)
  Vue.component(`${prefix}StaticMultiselect`, ControlStaticMultiselect)
  Vue.component(`${prefix}StaticTextarea`, ControlStaticTextarea)
  Vue.component(`${prefix}StaticDatepicker`, ControlStaticDatePicker)
  Vue.component('PrependComponent', PrependComponent)
  Vue.component('AppendComponent', AppendComponent)
}
