import { snakelize } from '@utils/case'

import CheckboxWidget from '../Widgets/CheckboxWidget'
import CheckboxWidgetSingle from '../Widgets/CheckboxWidgetSingle'
import RangeWidget from '../Widgets/RangeWidget'
import RadioWidget from '../Widgets/RadioWidget'
import SelectWidget from '../Widgets/SelectWidget'
import MultiselectWidget from '../Widgets/MultiselectWidget'
import AutocompleteMultiselectWidget from '../Widgets/AutocompleteMultiselectWidget'
import BooleanWidget from '../Widgets/BooleanWidget'
import ColorWidget from '../Widgets/ColorWidget'
import SearchWidget from '../Widgets/SearchWidget'
import DateRangeWidget from '../Widgets/DateRangeWidget'

const WIDGETS_MAP = {
  checkbox: CheckboxWidget,
  'checkbox-single': CheckboxWidgetSingle,
  range: RangeWidget,
  radio: RadioWidget,
  color: ColorWidget,
  select: SelectWidget,
  date: DateRangeWidget,
  BooleanWidget,
  AutocompleteMultiselectWidget,
  MultiselectWidget,
  search: SearchWidget,
}

export default {
  widgetsMap: WIDGETS_MAP,

  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,
      }
    },
  },

  methods: {
    onlyValue(key, value) {
      this.$emit('input', { [key]: value })
    },

    updateValue(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },

    updateMultipleValues(key, values) {
      const res = {}
      const [min, max] = values
      const snakelizedKey = snakelize(key)

      res[`${snakelizedKey}__gte`] = min
      res[`${snakelizedKey}__lte`] = max

      this.$emit('input', { ...this.value, ...res })
    },
  },
}
