import CompareToggleButton from './ToggleButton'
import CompareViewButton from './ViewButton'
import CompareView from './View'
import CompareRemoveButton from './RemoveButton'

export default function Components(Vue) {
  Vue.component('compare-toggle-button', CompareToggleButton)
  Vue.component('compare-view-button', CompareViewButton)
  Vue.component('compare-view', CompareView)
  Vue.component('compare-remove-button', CompareRemoveButton)
}
