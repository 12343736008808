import { route, prefixRoutes } from '@md/routing'
import { REDIRECTS } from '@md/users/permissions'

import { createRoutes as createOrdersRoutes } from './modules/orders/router'
import { createRoutes as createSettingsRoutes } from './modules/settings/router'
import { createRoutes as createInstructionsRoutes } from './modules/instructions/router'
import { createRoutes as createCouponRoutes } from './modules/coupon/router'
import { createRoutes as createActsRoutes } from './modules/acts/router'
// import { createRoutes as createInvoicesRoutes } from './modules/invoices/router'
import { createRoutes as createLoyaltyRoutes } from './modules/loyalty/router'
import { createRoutes as createServiceRoutes } from './modules/service/router'
import { createRoutes as createUsersRoutes } from './modules/users/router'
import { createRoutes as createApplicationsRoutes } from './modules/applications/router'
import { createRoutes as createCargoRoutes } from './modules/cargo/router'
import { createRoutes as createDealerSellersRoutes } from './modules/dealer-sellers/router'
import { createRoutes as createRepairRoutes } from './modules/repair/router'
import { createRoutes as createRepairActRoutes } from './modules/repair-act/router'
import { createRoutes as createSparePartRoutes } from './modules/spare-part/router'

const Profile = () => import('./views/Profile')

export function createRoutes(options) {
  return [
    route('', Profile, null, {
      children: [
        prefixRoutes('orders/', createOrdersRoutes(options)),
        prefixRoutes('instructions/', createInstructionsRoutes(options)),
        prefixRoutes('coupon/', createCouponRoutes(options)),
        prefixRoutes('acts/', createActsRoutes(options)),
        // prefixRoutes('invoices/', createInvoicesRoutes(options)),
        prefixRoutes('loyalty/', createLoyaltyRoutes(options)),
        prefixRoutes('service-repair/', createServiceRoutes(options)),
        prefixRoutes('users/', createUsersRoutes(options)),
        prefixRoutes('applications/', createApplicationsRoutes(options)),
        prefixRoutes('settings/', createSettingsRoutes(options)),
        prefixRoutes('cargo/', createCargoRoutes(options)),
        prefixRoutes('dealer/', createDealerSellersRoutes(options)),
        prefixRoutes('repair/', createRepairRoutes(options)),
        prefixRoutes('repair-act/', createRepairActRoutes(options)),
        prefixRoutes('spare-part/', createSparePartRoutes(options)),
        {
          path: '*',
          redirect: { name: REDIRECTS[window.role] },
        },
      ],
    }),
  ]
}
