import {
  createResource,
  postResource,
  putResource,
  patchResource,
  deleteResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'
import { simpleTransformer } from '@resource/transformer'

const MODEL_PREFIX = 'profile'

const USER_LOGIN = prefixAPI('/login/', MODEL_PREFIX)
const USER_REGISTER = prefixAPI('/registration/', MODEL_PREFIX)
const USER_PASSWORD_SET = prefixAPI('/set-password/', MODEL_PREFIX)
const USER_LOGOUT = prefixAPI('/logout/', MODEL_PREFIX)
const USER_PASSWORD_RESET_CONFIRM = prefixAPI('/password/reset/confirm/', MODEL_PREFIX)
const USER_PASSWORD_RESET = prefixAPI('/password/reset/', MODEL_PREFIX)
const USER_PASSWORD_CHANGE = prefixAPI('/password/change/', MODEL_PREFIX)
const USER_EMAIL_CONFIRM = prefixAPI('/account-confirm-email/', MODEL_PREFIX)

const HEADER_INFO = prefixAPI('/headerinfo/', MODEL_PREFIX)
const FOOTER_INFO = prefixAPI('/footerinfo/', MODEL_PREFIX)

const USER_ADDRESS_LIST = prefixAPI('/address/', MODEL_PREFIX)
const USER_ADDRESS_ADD = prefixAPI('/address/', MODEL_PREFIX)
export const USER_DEPARTMENTS_LIST = prefixAPI('/post_departments/{?search,limit,offset}', MODEL_PREFIX)
const USER_DEPARTMENTS_REMOVE = prefixAPI('/post_departments/{id}/', MODEL_PREFIX)
const USER_DEPARTMENTS_ADD = prefixAPI('/post_departments/{id}/add_department/', MODEL_PREFIX)

const USER_COMPANY_CREATE = prefixAPI('/company/', MODEL_PREFIX)
const USER_COMPANY_ACTIVATE = prefixAPI('/company/{id}/make_active/', MODEL_PREFIX)

const PRICE_LIST_REQUEST = prefixAPI('/price-email/get_price_by_email/', MODEL_PREFIX)
const SUBSTANDARD_PRICE_LIST_REQUEST = prefixAPI('/substandard-email/get_substandard_by_email/', MODEL_PREFIX)

const CONTRACTS_LIST = prefixAPI('get-contracts/')
const USER_STATUSES = prefixAPI('/user_statuses/', MODEL_PREFIX)
const USER_CARD_UPDATE = prefixAPI('/user_info/card_change/', MODEL_PREFIX)
const USER_INFO_UPDATE = prefixAPI('/user_info/info/', MODEL_PREFIX)

export const userLoginResource = createResource(USER_LOGIN, postResource)
export const userRegisterResource = createResource(USER_REGISTER, postResource)
export const passwordSetResource = createResource(USER_PASSWORD_SET, postResource)
export const logoutResource = createResource(USER_LOGOUT, postResource, simpleTransformer)
export const passwordResetResource = createResource(USER_PASSWORD_RESET, postResource)
export const passwordResetConfirmResource = createResource(USER_PASSWORD_RESET_CONFIRM, postResource)
export const passwordChangeResource = createResource(USER_PASSWORD_CHANGE, postResource)
export const emailConfirmResource = createResource(USER_EMAIL_CONFIRM, postResource)

export const headerInfoResource = createResource(HEADER_INFO)
export const footerInfoResource = createResource(FOOTER_INFO)

export const userAddressListResource = createResource(USER_ADDRESS_LIST)
export const userAddressAddResource = createResource(USER_ADDRESS_ADD, postResource)
export const userDepartmentsListResource = createResource(USER_DEPARTMENTS_LIST)
export const userDepartmentsAddResource = createResource(USER_DEPARTMENTS_ADD, putResource)
export const userDepartmentsRemoveResource = createResource(USER_DEPARTMENTS_REMOVE, deleteResource)

export const userCompanyCreateResource = createResource(USER_COMPANY_CREATE, postResource)
export const userCompanyActivateResource = createResource(USER_COMPANY_ACTIVATE, putResource)

export const priceListRequestResource = createResource(PRICE_LIST_REQUEST)
export const substandardPriceListRequestResource = createResource(SUBSTANDARD_PRICE_LIST_REQUEST)

export const contractsListRequestResource = createResource(CONTRACTS_LIST, postResource)
export const userStatusesResource = createResource(USER_STATUSES)
export const userCardUpdateResource = createResource(USER_CARD_UPDATE, patchResource)
export const userInfoUpdateResource = createResource(USER_INFO_UPDATE, patchResource)
