import Multiselect from 'vue-multiselect'
import { createHOCc } from 'vue-hoc'

export const multiselectDefaults = createHOCc({
  props: {
    placeholder: {},
    tagPlaceholder: {},
    showLabels: {},
    searchable: {},
    multiple: {},
    closeOnSelect: {},
    hideSelected: {},
    taggable: {},
    optionsLimit: {},
    loading: {},
    internalSearch: {
      default: true,
    },
  },
}, {
  props: {
    openDirection() {
      return 'bottom'
    },
    tagPlaceholder() {
      return this.tagPlaceholder || ''
    },
    showLabels() {
      return this.showLabels || false
    },
    searchable() {
      return this.searchable || false
    },
    multiple() {
      return this.multiple || false
    },
    closeOnSelect() {
      return this.closeOnSelect || true
    },
    hideSelected() {
      return this.hideSelected || false
    },
    taggable() {
      return this.taggable || true
    },
    placeholder() {
      return this.placeholder || ''
    },
    optionsLimit() {
      return this.optionsLimit || 1000
    },
    loading() {
      return this.loading || false
    },
    internalSearch() {
      return this.internalSearch
    },
  },
})

export default multiselectDefaults(Multiselect)
