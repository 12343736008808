<template lang='pug'>
  include /mixins
  +b.ds-panel.--space_xl(v-if="Object.keys(filters).length || needSearch")
    +e.element.--offset_bottom
      +b.card.relative
        +b.cabinet-filter
          +e.wrapper
            +b.i-preloader.--absolute.--variant_1(:class="{'is-active': loading}")
              +e.item.--size_md
            +e.content
              filter-ui(
                :disabled="loading"
                :value="prepared"
                :filters="filters"
                :pagination='pagination'
                :needSearch="needSearch"
                @input="handleInputFilter"
              )
                +b.g-cell.g-cols.--narrow
                  +b.i-button(
                    @click.prevent="handleInputFilter({})"
                  )
                    +e.wrapper
                      +e.icon
                        +b.I.icon-clear-filter
</template>

<script>

import ControllerMixin from './mixins/ControllerMixin'

export default {
  mixins: [ControllerMixin],
}

</script>
