import isShallowEqual from '@wordpress/is-shallow-equal'
import FilterUi from '../Ui'

export default {
  props: [
    'loading',
    'value',
    'receive',
    'all',
    'available',
    'pagination',
    'label',
    'additionalResource',
    'totalItems',
  ],

  components: {
    FilterUi,
  },

  data() {
    return {
      prepared: {},
      isActive: false,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return
        }

        this.prepare(filters)
      },
    },
  },

  computed: {
    totalCount() {
      return Object.hasOwnProperty.call(this.pagination, 'total') ? this.pagination.total : this.value.total
    },

    filters() {
      return (this.all && this.all.item) ? this.all.item : []
    },
  },

  created() {
    this.receive({ label: this.label })
  },

  methods: {
    prepare(value) {
      this.prepared = value
    },

    handleInputFilter(value) {
      this.handleInput(value)
      this.filter()
    },

    handleInput(value) {
      this.prepare(value)
    },

    filter() {
      this.$emit('input', {
        ...this.value,
        filters: this.prepared,
        offset: 0,
      })
    },

    toggleOverflow() {
      const element = document.querySelector('html')
      const { pageYOffset } = window

      if (this.isActive) {
        element.style.top = `-${pageYOffset}px`
        element.classList.add('is-overflow')
      } else {
        const scrollOffset = Math.abs(element.offsetTop)

        element.removeAttribute('style')
        element.classList.remove('is-overflow')

        window.scrollTo({
          top: scrollOffset,
          behavior: 'auto',
        })
      }
    },

    openFilter() {
      this.isActive = true
      this.toggleOverflow()
    },

    hideFilter() {
      this.isActive = false
      this.toggleOverflow()
    },
  },
}
