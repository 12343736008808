export function createStore() {
  return {
    namespaced: true,

    state: {
      breadcrumbs: {},
    },

    mutations: {
      CHANGE_BREADCRUMBS(state, result) {
        state.breadcrumbs = result
      },

      UPDATE_CURRENT_CRUMB_TITLE(state, result) {
        state.breadcrumbs = state.breadcrumbs.map(el => {
          if (el.isCurrent) {
            el.title = result
          }
          return el
        })
      },
    },
  }
}
